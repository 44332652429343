import React, { useState } from 'react'
import ResponsiveBig from './ResponsiveBig'
import ResponsiveSmall from './ResponsiveSmall'
import { useMediaQuery } from 'react-responsive'

function MainNavbar() {


    const isTabletOrMobile = useMediaQuery(
        { query: '(max-width: 1224px)' }
    )

    return (
        <>
            {
                isTabletOrMobile ? <ResponsiveSmall /> : <ResponsiveBig />
            }

        </>
    )
}

export default MainNavbar
