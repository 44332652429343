import React, { useState, useEffect, useReducer } from "react";
import _ from "lodash";
import { Table, Flag, Label, Popup } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { BACKEND_URL } from "../../Config";
import LoadingCircle from "../Lotties/loadingCircle.json";
import Lottie from "react-lottie";
import ReactTimeAgo from "react-time-ago";
import { useHistory } from "react-router-dom";
import io from "socket.io-client";
import { sortedTableStyles } from "./styles";

const socket = io.connect(BACKEND_URL);

function tableReducer(state, action) {
  switch (action.type) {
    case "CHANGE_SORT":
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
        };
      }

      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: "ascending",
      };
    case "SET_STATE":
      return {
        ...state,
        data: action.reports,
      };
    default:
      throw new Error();
  }
}

const flagRenderer = (countryCode) => <Flag name={countryCode} />;

function SortableTable() {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const classes = sortedTableStyles(isMobile);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const [state, dispatch] = useReducer(tableReducer, {
    column: null,
    data: null,
    direction: null,
  });
  const { column, data, direction } = state;

  useEffect(() => {
    retrieveReports();
  }, []);

  const retrieveReports = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/reports/latest`);
      if (response.data.success) {
        dispatch({ type: "SET_STATE", reports: response.data?.reports });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const joinReportsRoom = () => {
    socket.emit("join_socket_room");
  };

  useEffect(() => {
    joinReportsRoom();
  }, []);

  const receiveReportData = () => {
    socket.on("receive_reports", (reports) => {
      dispatch({ type: "SET_STATE", reports });
      setIsLoading(false);
    });
  };

  useEffect(() => {
    receiveReportData();
  }, [socket]);

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingCircle,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const goToResultPage = (type, id) => {
    history.push(`/search-results?type=${type}&reportId=${id}`);
  };

  return (
    <>
      <Table unstackable sortable fixed style={classes.table}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              width={isTabletOrMobile ? 6 : 10}
              sorted={column === "url" ? direction : null}
              onClick={() => dispatch({ type: "CHANGE_SORT", column: "url" })}
              style={classes.urlHeader}
            >
              URL
            </Table.HeaderCell>

            <Table.HeaderCell
              width={2}
              sorted={column === "age" ? direction : null}
              onClick={() => dispatch({ type: "CHANGE_SORT", column: "age" })}
              style={classes.ageHeader}
            >
              Age
            </Table.HeaderCell>

            {!isMobile && (
              <Table.HeaderCell
                width={2}
                sorted={column === "abuseType" ? direction : null}
                onClick={() =>
                  dispatch({ type: "CHANGE_SORT", column: "abuseType" })
                }
                style={classes.typeHeader}
              >
                Type
              </Table.HeaderCell>
            )}

            {!isMobile && (
              <Table.HeaderCell
                width={2}
                sorted={column === "country" ? direction : null}
                onClick={() =>
                  dispatch({ type: "CHANGE_SORT", column: "country" })
                }
                style={classes.countryHeader}
              >
                Country
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>

        {isLoading ? (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan="16">
                <Lottie options={loadingOptions} height={40} width={40} />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ) : (
          <Table.Body>
            {data &&
              data.map(({ _id, url, date, type, countryCode }) => (
                <Table.Row key={_id}>
                  <Table.Cell>
                    <a
                      className="table-link"
                      onClick={() => {
                        goToResultPage(type, _id);
                      }}
                      style={classes.urlCell}
                    >
                      {url}
                    </a>
                  </Table.Cell>
                  <Table.Cell>
                    <ReactTimeAgo
                      date={new Date(date)}
                      locale="en-US"
                      style={classes.ageCell}
                    />
                  </Table.Cell>
                  {!isMobile && (
                    <Table.Cell>
                      {type === "abuseReport" ? (
                        <Popup
                          basic
                          content="Abuse Report"
                          trigger={
                            <Label
                              size={isTabletOrMobile ? "mini" : "tiny"}
                              color="brown"
                            >
                              AR
                            </Label>
                          }
                        />
                      ) : (
                        <Popup
                          basic
                          content="Active Scan"
                          trigger={
                            <Label
                              size={isTabletOrMobile ? "mini" : "tiny"}
                              color="blue"
                            >
                              AS
                            </Label>
                          }
                        />
                      )}
                    </Table.Cell>
                  )}

                  {!isMobile && (
                    <Table.Cell textAlign="center">
                      {flagRenderer(countryCode)}
                    </Table.Cell>
                  )}
                </Table.Row>
              ))}
          </Table.Body>
        )}
      </Table>
    </>
  );
}

export default SortableTable;
