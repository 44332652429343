import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { BACKEND_URL } from "../../Config";
import { useMediaQuery } from "react-responsive";
import Loading from "../Lotties/loading.json";
import Lottie from "react-lottie";
import Error from "../Lotties/error.json";
import Success from "../Lotties/success.json";
import { SignInButton } from "./styles";
import { confirmation } from "./styles";

function Confirmation() {
  let { otp, userId } = useParams();
  const history = useHistory();
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({
    title: "",
    msg: "",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const classes = confirmation(isMobile);

  useEffect(() => {
    verificationProcess();
  }, [otp, userId]);

  const verificationProcess = async () => {
    if (otp && userId) {
      try {
        const verified = await axios.post(`${BACKEND_URL}/users/confirmation`, {
          otp,
          userId,
        });
        if (verified.data.success) {
          setIsVerified(true);
        }
      } catch (error) {
        if (error.response?.data.errorObj) {
          setError(error.response.data.errorObj);
        } else if (error.response?.data.error) {
          setError({
            title: error.response.data.error,
          });
        }
        setIsVerified(false);
      }
      setIsLoading(false);
    }
  };

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const successOptions = {
    loop: false,
    autoplay: true,
    animationData: Success,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: Error,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const goToLoginPage = () => {
    history.push("/login");
  };

  const handleVerificationProcess = () => {
    if (isLoading) {
      return (
        <div style={classes.loadingContainer}>
          <Lottie options={loadingOptions} height={150} width={150} />
        </div>
      );
    } else {
      if (isVerified) {
        return (
          <div style={classes.verifiedContainerOuter}>
            <div style={classes.verfiedContainerInner}>
              <div>
                <Lottie options={successOptions} height={200} width={200} />
              </div>
              <div>
                <div style={classes.verifiedTextContainer}>
                  <p style={classes.verifiedTitle}>
                    Your account has been verified!
                  </p>
                  <p style={classes.verifiedSubTitle}>
                    You can now sign in with your new account.
                  </p>
                  <SignInButton onClick={goToLoginPage}>Sign in</SignInButton>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div style={classes.notVerifiedContainerOuter}>
            <div style={classes.notVerifiedContainerOuter}>
              <div>
                <Lottie options={errorOptions} height={150} width={150} />
              </div>
              <div>
                <div style={classes.errorTextContainer}>
                  <p style={classes.errorTitle}>{error.title}</p>
                  <p style={classes.errorMsg}>{error.msg}</p>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  };

  return (
    <>
      <Grid style={classes.grid}>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={2} computer={2} />
          <Grid.Column mobile={16} tablet={12} computer={12}>
            {handleVerificationProcess()}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={2} computer={2} />
        </Grid.Row>
      </Grid>
    </>
  );
}

export default Confirmation;
