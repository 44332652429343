import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Grid, Card, Icon, Breadcrumb } from "semantic-ui-react";
import { Label } from "semantic-ui-react";
import axios from "axios";
import Lottie from "react-lottie";
import Loading from "../../Lotties/loading.json";
import { BACKEND_URL } from "../../../Config";
import Pagination from "../Pagination";
import { paginate } from "../../../utils/paginate";
import { useMediaQuery } from "react-responsive";
import Filters from "./Filters";
import useQuery from "../../../utils/useQuery";
import { indexStyles } from "./styles";

function MatchedResults() {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const classes = indexStyles(isMobile);
  const query = useQuery();
  const searchString = query.get("q");
  const PAGE_SIZE = 8;
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [reportDomains, setReportDomains] = useState(null);
  const [reportCount, setReportCount] = useState(null);
  const [scanDomains, setScanDomains] = useState(null);
  const [scanCount, setScanCount] = useState(null);

  const [activePage, setActivePage] = useState(1);
  const [filter, setFilter] = useState({
    abuseReport: false,
    activeScan: false,
  });

  useEffect(() => {
    retrieveSearchResults();
  }, [searchString]);

  const retrieveSearchResults = async () => {
    if (searchString !== null) {
      setIsLoading(true);
      try {
        const results = await axios.post(`${BACKEND_URL}/search`, {
          searchString,
        });
        if (results.data.success) {
          setReportDomains(results.data?.reportDomains);
          setReportCount(results.data?.reportCount);
          setScanDomains(results.data?.scanDomains);
          setScanCount(results.data?.scanCount);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handlePageChange = (e, pageInfo) => {
    setActivePage(pageInfo.activePage);
  };

  const goToTimeline = (item) => {
    const domain = item?.domain.replace(".", "%2e");
    history.push(
      `/timeline?q=${searchString}&domain=${domain}&type=${item.type}`
    );
  };

  const giveItemCount = () => {
    if (filter.abuseReport && !filter.activeScan) {
      return reportDomains?.length;
    } else if (!filter.abuseReport && filter.activeScan) {
      return scanDomains?.length;
    } else {
      return reportDomains?.length + scanDomains?.length;
    }
  };

  const giveReportedCount = (item) => {
    if (item?.type === "abuseReport") {
      return reportCount[item?.domain];
    } else if (item?.type === "activeScan") {
      return scanCount[item?.domain];
    }
  };

  const giveLabelColor = (item) => {
    if (item?.type === "abuseReport") {
      return "blue";
    } else if (item?.type === "activeScan") {
      return "brown";
    }
  };

  const giveType = (item) => {
    if (item?.type === "abuseReport") {
      return "Abuse Report";
    } else if (item?.type === "activeScan") {
      return "Active Scan";
    }
  };

  const displayDomains = () => {
    if (reportDomains === null && scanDomains === null) {
      return (
        <div style={classes.errorText}>
          Something went wrong. Please try again later.
        </div>
      );
    } else if (reportDomains?.length === 0 && scanDomains?.length === 0) {
      return (
        <div>
          <p style={classes.noResultsText}>We didn't find any results</p>
          <p style={classes.noResultsSubText}>
            Make sure that everything is spelt correctly.
          </p>
        </div>
      );
    } else if (reportDomains !== null && scanDomains !== null) {
      var domains = [];
      if (filter.abuseReport && !filter.activeScan) {
        domains = paginate(reportDomains, activePage, PAGE_SIZE);
      } else if (!filter.abuseReport && filter.activeScan) {
        domains = paginate(scanDomains, activePage, PAGE_SIZE);
      } else {
        domains = paginate(
          [...reportDomains, ...scanDomains],
          activePage,
          PAGE_SIZE
        );
      }

      return (
        <div style={classes.resultContainer}>
          {domains.length > 0 &&
            domains.map((item, index) => (
              <div
                key={item?.domain + index}
                onClick={() => goToTimeline(item)}
                style={classes.cardContainer}
              >
                <Card fluid style={classes.card}>
                  <Card.Content>
                    <div style={classes.cardContent}>
                      <p style={classes.domain}>{item?.domain}</p>
                      <Label color={giveLabelColor(item)}>
                        {giveType(item)}
                      </Label>
                    </div>
                  </Card.Content>
                  <Card.Content style={classes.reportCountCardContent}>
                    <Icon name="file alternate outline" />
                    {giveReportedCount(item)} reports
                  </Card.Content>
                </Card>
              </div>
            ))}
          <div style={classes.paginationContainer}>
            <Pagination
              itemCount={giveItemCount()}
              PAGE_SIZE={PAGE_SIZE}
              activePage={activePage}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <Grid>
        <Grid.Row style={classes.gridRow}>
          <Grid.Column mobile={16} tablet={4} computer={4} />
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Breadcrumb style={classes.breadCrumb}>
              <Breadcrumb.Section>
                <Link to="/" style={classes.homeLink}>
                  Home
                </Link>
              </Breadcrumb.Section>
              <Breadcrumb.Divider
                icon="right angle"
                style={classes.breadcrumbDivider}
              />
              <Breadcrumb.Section active style={classes.breadcrumbSection}>
                Search Results
              </Breadcrumb.Section>
            </Breadcrumb>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={4} computer={4} />
        </Grid.Row>

        <Grid.Row>
          <Grid.Column mobile={16} tablet={4} computer={4}>
            {!isLoading &&
              reportDomains?.length > 0 &&
              scanDomains?.length > 0 && (
                <Filters
                  setFilter={setFilter}
                  setActivePage={setActivePage}
                ></Filters>
              )}
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={8}>
            {isLoading ? (
              <Lottie options={loadingOptions} height={150} width={150} />
            ) : (
              displayDomains()
            )}
          </Grid.Column>

          <Grid.Column mobile={16} tablet={4} computer={4} />
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default MatchedResults;
