import React from "react";
import { List, Grid, Divider, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { indexStyles } from "./styles";

function Footer() {
  const isMobile = useMediaQuery({ query: "(max-width: 750px)" });
  const classes = indexStyles(isMobile);

  return (
    <div>
      <Divider style={classes.divider} />
      <Grid>
        <Grid.Row style={classes.gridRow}>
          <Grid.Column mobile={16} tablet={1} computer={2} />
          <Grid.Column
            mobile={16}
            tablet={14}
            computer={12}
            style={classes.gridColumn}
          >
            <div style={classes.textContainer}>
              <div style={classes.copyrightWrapper}>
                <div>
                  Copyright URL Abuse © 2022 {isMobile ? <br /> : "-"} All
                  rights reserved. [ Developed by{" "}
                  <a
                    href="https://github.com/web-telescope"
                    target="_blank"
                    style={classes.webtelescopeText}
                  >
                    WebTelescope{" "}
                  </a>
                  ]
                </div>
              </div>

              <div style={classes.listContainer}>
                <List horizontal style={classes.socialList}>
                  <List.Item
                    as="a"
                    target="_blank"
                    href="https://scorelab.org/"
                  >
                    <Icon style={classes.socailIcon} name="linkedin" />
                  </List.Item>
                  <List.Item
                    as="a"
                    target="_blank"
                    href="https://scorelab.org/"
                  >
                    <Icon style={classes.socailIcon} name="twitter" />
                  </List.Item>
                </List>

                <List horizontal={!isMobile} style={classes.textList}>
                  <List.Item>
                    <Link to={`/#`} style={classes.listItem}>
                      Contact us
                    </Link>
                  </List.Item>
                  <List.Item>
                    <Link to={`/terms`} style={classes.listItem}>
                      Terms and Conditions
                    </Link>
                  </List.Item>
                  <List.Item>
                    <Link to={`/privacy`} style={classes.listItem}>
                      Privacy Policy
                    </Link>
                  </List.Item>
                </List>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={1} computer={2} />
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default Footer;
