import React, { useState } from "react";
import { Button, Modal, Icon, Message } from "semantic-ui-react";
import axios from "axios";
import { BACKEND_URL } from "../../../Config";
import useHandleAuthFail from "../../../utils/useHandleAuthFail";
import { useLocation } from "react-router-dom";
import { deleteModalStyles } from "./styles";

function DeleteModal(props) {
  const {
    open,
    setOpen,
    deleteKeyId,
    setDeleteKeyId,
    apiKeys,
    setApiKeys,
  } = props;
  const classes = deleteModalStyles();
  const location = useLocation();
  const handleAuthFail = useHandleAuthFail();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    setIsError(false);
    if (deleteKeyId !== null) {
      try {
        const response = await axios.patch(
          `${BACKEND_URL}/apiKeys/delete`,
          { keyId: deleteKeyId }
        );
        if (response.data.success) {
          const newApiKeys = apiKeys.filter((i) => i._id !== deleteKeyId);
          setApiKeys(newApiKeys);
          setIsLoading(false);
          setOpen(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleAuthFail(location.pathname);
          return;
        }
        setIsError(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal
      onClose={() => {
        setDeleteKeyId(null);
        setOpen(false);
      }}
      open={open}
      dimmer={"blurring"}
      size={"tiny"}
      closeIcon
    >
      <Modal.Header style={classes.modalHeader}>
        <Icon name="trash" /> Delete API Key
      </Modal.Header>
      <Modal.Content>
        {isError && (
          <Message error>Something went wrong. Please try again later.</Message>
        )}
        <p style={classes.deleteText}>
          Are you sure you want to delete this API key ?
          <br />
          This cannot be undone.
        </p>
        <div style={classes.btnContainer}>
          <Button
            color="orange"
            style={classes.deleteBtn}
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default DeleteModal;
