import React, { useState, useEffect } from "react";
import { Table, Icon, Button, Grid } from "semantic-ui-react";
import NewKeyModal from "./NewKeyModal";
import axios from "axios";
import { BACKEND_URL } from "../../../Config";
import Lottie from "react-lottie";
import LoadingCircle from "../../Lotties/loadingCircle.json";
import DeleteModal from "./DeleteModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useMediaQuery } from "react-responsive";
import useHandleAuthFail from "../../../utils/useHandleAuthFail";
import { useLocation } from "react-router-dom";
import { indexStyles } from "./styles";

function Settings() {
  const isMobile = useMediaQuery({ query: "(max-width: 750px)" });
  const classes = indexStyles(isMobile);
  const location = useLocation();
  const handleAuthFail = useHandleAuthFail();

  const [open, setOpen] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [deleteKeyId, setDeleteKeyId] = useState(null);

  const [apiKeys, setApiKeys] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [apiKeysHover, setApiKeysHover] = useState([]);

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingCircle,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    retrieveApiKeys();
  }, []);

  useEffect(() => {
    let temp = []; // used to blur api keys
    apiKeys.map((key) => {
      temp = [...temp, false];
    });
    setApiKeysHover(temp);
  }, [apiKeys]);

  const retrieveApiKeys = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BACKEND_URL}/apiKeys/all`);
      if (response.data.success) {
        setApiKeys(response.data.apiKeys);
        let temp = []; // used to blur api keys
        response.data.apiKeys.map((key) => {
          temp = [...temp, false];
        });
        setApiKeysHover(temp);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleAuthFail(location.pathname);
        return;
      }
      setIsLoading(false);
      setIsError(true);
    }
  };

  const handleDeleteBtnClick = (keyId) => {
    setDeleteKeyId(keyId);
    setOpenDelModal(true);
  };

  // to make the string responsive
  const addSpacesToApiKey = (key) => {
    const spacedKey =
      key.substring(0, 11) +
      " " +
      key.substring(11, 22) +
      " " +
      key.substring(22, 33) +
      " " +
      key.substring(33, key.length);

    return spacedKey;
  };

  const displayApiKeys = () => {
    if (isLoading) {
      return (
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan="16">
              <Lottie options={loadingOptions} height={40} width={40} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      );
    } else if (isError) {
      return (
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan="16">
              <p style={{ textAlign: "center" }}>
                Something went wrong. Please try again later.
              </p>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      );
    } else if (apiKeys.length === 0) {
      return (
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan="16">
              <p style={{ textAlign: "center" }}>
                You dont have any API keys yet.
              </p>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      );
    } else {
      return (
        <Table.Body>
          {apiKeys.map((item, index) => (
            <Table.Row key={item?._id}>
              <Table.Cell width="12">
                <Grid>
                  <Grid.Column mobile={16} tablet={10} computer={10}>
                    <div>
                      <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                        Key:
                      </span>
                      <span
                        style={{
                          color: apiKeysHover[index] ? "#000" : "transparent",
                          textShadow: apiKeysHover[index]
                            ? ""
                            : "0 0 5px rgba(0,0,0,0.4)",
                          userSelect: "none",
                        }}
                        onMouseEnter={() => {
                          let newApiKeysHover = apiKeysHover.map((item, i) => {
                            if (i === index) {
                              return true;
                            }
                            return item;
                          });
                          setApiKeysHover(newApiKeysHover);
                        }}
                        onMouseLeave={() => {
                          let newApiKeysHover = apiKeysHover.map((item, i) => {
                            if (i === index) {
                              return false;
                            }
                            return item;
                          });
                          setApiKeysHover(newApiKeysHover);
                        }}
                      >
                        {addSpacesToApiKey(item.key)}
                      </span>
                    </div>
                    <div>
                      <span style={classes.description}>Description:</span>
                      {item.description}
                    </div>
                  </Grid.Column>

                  <Grid.Column
                    mobile={16}
                    tablet={6}
                    computer={6}
                    style={classes.gridColumn}
                    verticalAlign="middle"
                  >
                    <div style={classes.btnContainer}>
                      <CopyToClipboard text={item.key}>
                        <Button size="small" color={"blue"}>
                          <Icon name="copy" style={classes.btnIcon} />
                          Copy
                        </Button>
                      </CopyToClipboard>
                      <Button
                        size="small"
                        color={"orange"}
                        onClick={() => {
                          handleDeleteBtnClick(item?._id);
                        }}
                        style={classes.deleteBtn}
                      >
                        <Icon name="trash" style={classes.btnIcon} />
                        Delete
                      </Button>
                    </div>
                  </Grid.Column>
                </Grid>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      );
    }
  };

  return (
    <div>
      <NewKeyModal open={open} setOpen={setOpen} setApiKeys={setApiKeys} />
      {deleteKeyId && (
        <DeleteModal
          open={openDelModal}
          setOpen={setOpenDelModal}
          deleteKeyId={deleteKeyId}
          setDeleteKeyId={setDeleteKeyId}
          apiKeys={apiKeys}
          setApiKeys={setApiKeys}
        />
      )}

      <div style={classes.container}>
        <p style={classes.apiKeys}>API Keys</p>
        <div>
          <Button
            color="green"
            style={classes.createBtn}
            onClick={() => {
              setOpen(true);
            }}
          >
            <Icon name="add" style={classes.icon} />
            Create new API key
          </Button>
        </div>
      </div>
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Key/ Description</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {displayApiKeys()}
      </Table>
    </div>
  );
}

export default Settings;
