import React, { useState, useEffect } from "react";
import { Grid, Header, Segment, Breadcrumb, Feed } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../Lotties/loading.json";
import Lottie from "react-lottie";
import { BACKEND_URL } from "../../../Config";
import axios from "axios";
import ReactTimeAgo from "react-time-ago";
import Pagination from "../Pagination";
import { paginate } from "../../../utils/paginate";
import { useMediaQuery } from "react-responsive";
import useQuery from "../../../utils/useQuery";
import { indexStyles } from "./styles";

function TimelineReport() {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const classes = indexStyles(isMobile);

  const history = useHistory();
  const query = useQuery();
  const searchString = query.get("q");
  const domain = query.get("domain");
  const type = query.get("type");

  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const pageSize = 8;

  useEffect(() => {
    retieveReports();
  }, [domain, type]);

  const retieveReports = async () => {
    if (!searchString || !domain || !type) {
      setIsLoading(false);
      return;
    }

    try {
      if (type === "abuseReport") {
        const response = await axios.post(`${BACKEND_URL}/search/report`, {
          domain,
        });
        if (response.data.success) {
          setReports(response.data?.reports);
        }
      } else if (type === "activeScan") {
        const response = await axios.post(`${BACKEND_URL}/search/scan`, {
          domain,
        });
        if (response.data.success) {
          setReports(response.data?.scans);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handlePageChange = (e, pageInfo) => {
    setActivePage(pageInfo.activePage);
  };

  const goToSearchResults = () => {
    history.push(`/search?q=${searchString.replace(".", "%2e")}`);
  };

  const giveType = () => {
    if (type === "abuseReport") {
      return "Abuse Reports";
    } else if (type === "activeScan") {
      return "Active Scans";
    }
  };

  const goToResultPage = (id) => {
    history.push(`/search-results?type=${type}&reportId=${id}`);
  };

  const displayReports = () => {
    if (reports === null) {
      return;
    }
    if (reports !== null && reports?.length > 0) {
      const paginatedReports = paginate(reports, activePage, pageSize);

      return (
        <div style={classes.reportContainer}>
          <Breadcrumb style={classes.breadCrumb}>
            <Breadcrumb.Section>
              <Link to="/" style={classes.homeLink}>
                Home
              </Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider
              icon="right angle"
              style={classes.breadcrumbDivider}
            />
            <Breadcrumb.Section style={classes.searchResultsSection}>
              <div style={classes.searchResults} onClick={goToSearchResults}>
                Search Results
              </div>
            </Breadcrumb.Section>
            <Breadcrumb.Divider
              icon="right angle"
              style={classes.breadcrumbDivider}
            />
            <Breadcrumb.Section active style={classes.timelineSection}>
              Timeline
            </Breadcrumb.Section>
          </Breadcrumb>

          <Header as="h3" textAlign="left" style={classes.typeHeader}>
            {giveType()}
          </Header>
          <Segment style={classes.segment}>
            <Feed>
              {paginatedReports &&
                paginatedReports.length > 0 &&
                paginatedReports.map((report, index) => (
                  <Feed.Event
                    key={report._id}
                    style={{
                      paddingBottom:
                        index + 1 === paginatedReports?.length ? "0px" : "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      goToResultPage(report._id);
                    }}
                  >
                    <Feed.Label
                      icon={<Icon name="dot circle outline" color="orange" />}
                    />
                    <Feed.Content>
                      <Feed.Date
                        content={
                          <ReactTimeAgo
                            date={new Date(report.date)}
                            locale="en-US"
                            style={classes.timeAgo}
                          />
                        }
                      />
                      <div style={classes.feedContainer}>
                        <Feed.Label style={classes.feedLabel}>
                          {report.url}
                        </Feed.Label>
                        {type === "abuseReport" ? (
                          <Feed.Summary style={classes.feedSummary}>
                            {report.description}
                          </Feed.Summary>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Feed.Content>
                  </Feed.Event>
                ))}
            </Feed>
          </Segment>
          <div style={classes.pageContainer}>
            <Pagination
              itemsCount={reports?.length}
              pageSize={pageSize}
              activePage={activePage}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      );
    }
  };

  const displayError = () => {
    if (!isLoading && reports === null) {
      return (
        <div>
          <p style={classes.errorText}>
            <Icon name="exclamation" style={classes.errorIcon} />
            Information Not Available.
          </p>
        </div>
      );
    }
  };

  return (
    <div>
      <Grid>
        <Grid.Row>
          {/* Error is displayed in different grid, because otherwise its centered in the page like the timeline */}
          <Grid.Column mobile={isMobile ? 16 : 1} tablet={2} computer={2} />
          <Grid.Column mobile={isMobile ? 16 : 1} tablet={12} computer={12}>
            {displayError()}
          </Grid.Column>
          <Grid.Column mobile={isMobile ? 16 : 1} tablet={2} computer={2} />

          <Grid.Column mobile={isMobile ? 16 : 1} tablet={2} computer={4} />
          <Grid.Column mobile={isMobile ? 16 : 1} tablet={12} computer={8}>
            {isLoading ? (
              <Lottie options={loadingOptions} height={150} width={150} />
            ) : (
              displayReports()
            )}
          </Grid.Column>
          <Grid.Column mobile={isMobile ? 16 : 1} tablet={2} computer={4} />
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default TimelineReport;
