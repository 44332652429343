import { colors } from "../../../Themes";

export const indexStyles = (isMobile) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  apiKeys: { fontSize: "20px", color: colors.greyBlack },
  createBtn: { marginRight: "0px" },
  icon: { color: "#fff" },
  description: { fontWeight: "bold", marginRight: "5px" },
  gridColumn: { paddingTop: isMobile ? "0px" : "" },
  btnContainer: {
    display: "flex",
    justifyContent: isMobile ? "flex-start" : "center",
  },
  deleteBtn: { marginLeft: "7px" },
  btnIcon: { marginRight: "5px" },
});

export const deleteModalStyles = () => ({
  modalHeader: { color: colors.greyBlack },
  btnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  deleteText: { fontSize: "15px" },
  deleteBtn: { marginRight: "0px" },
});

export const newKeyStyles = () => ({
  modalHeader: { color: colors.greyBlack },
  btnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  newKeyBtn: { marginRight: "0px" },
  newKeyIcon: { color: "#fff" },
});
