import React, { useState, useEffect, useRef } from "react";
import { Card, Container, Form } from "semantic-ui-react";
import { Header, Input, Message } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import setAuthToken from "../../utils/setAuthToken";
import axios from "axios";
import jwt_decode from "jwt-decode";
import * as actions from "../../store/actions/actionTypes";
import { BACKEND_URL } from "../../Config";
import { useMediaQuery } from "react-responsive";
import { indexStyles } from "./styles";
import ReCaptcha from "../Helpers/ReCaptcha";

function Login() {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const classes = indexStyles(isMobile);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const recaptchaRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [resetSuccessMsg, setResetSuccessMsg] = useState(
    location.state?.resetSuccessMsg ? location.state?.resetSuccessMsg : null
  );

  const [previousPage, setPreviousPage] = useState(
    location.state?.pathname ? location.state?.pathname : null
  );

  const [state, setstate] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: null,
    password: null,
  });
  const [genericError, setGenericError] = useState("");
  const [isVerificationFailed, setIsVerificationFailed] = useState(false);

  useEffect(() => {
    return () => {
      setIsLoading(false); // Component unmount cleanup
    };
  }, []);

  const displayMessage = () => {
    if (genericError?.length > 0) {
      return <Message style={classes.message} error content={genericError} />;
    } else if (resetSuccessMsg) {
      return (
        <Message style={classes.message} success content={resetSuccessMsg} />
      );
    }
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const id = target.id;

    if (errors[id] !== null) {
      setErrors({
        ...errors,
        [id]: null,
      });
    }

    setstate({
      ...state,
      [id]: value,
    });
  };

  const goToForgotPassword = () => {
    history.push("/forgot-password");
  };

  const submitForm = async (e) => {
    e.preventDefault();

    const recaptchaToken = recaptchaRef.current.getValue();

    // Check recaptcha verification
    if (recaptchaToken?.length === 0) {
      setIsVerificationFailed(true);
      return;
    }

    recaptchaRef.current.reset();
    setIsVerificationFailed(false);
    setIsLoading(true);
    setResetSuccessMsg(null);
    setGenericError("");

    try {
      const response = await axios.post(`${BACKEND_URL}/users/login`, {
        ...state,
        recaptchaToken,
      });

      if (response.data.success) {
        const { token } = response.data;
        const { encodedUserData } = response.data; // Contains Firstname, Lastname

        localStorage.setItem("urlAbuseToken", token);
        localStorage.setItem("urlAbuseUser", encodedUserData);

        const decoded = jwt_decode(token); // Decode token to get user auth data
        const decodedUserData = JSON.parse(
          Buffer.from(encodedUserData, "base64").toString("utf8")
        );

        setAuthToken(token); // Set token to Auth header
        dispatch({
          type: actions.SET_USER_AUTH_DATA,
          payload: decoded,
        });
        dispatch({
          type: actions.SET_USER_DATA,
          payload: decodedUserData,
        });

        if (previousPage) {
          history.push(previousPage);
        } else {
          history.push("/");
        }
      }
    } catch (error) {
      setIsLoading(false);

      if (error.response?.data.errors) {
        setErrors(error.response.data.errors);
      } else if (error.response?.data.error) {
        setGenericError(error.response.data.error);
      } else {
        setGenericError("Something went wrong. Please try again later.");
      }
    }
  };

  return (
    <div>
      <div style={classes.wrapper}>
        {displayMessage()}
        <Card style={classes.card}>
          <Container>
            <Form style={classes.form} onSubmit={submitForm}>
              <Header as="h2" textAlign="center">
                Sign In
              </Header>

              <Form.Group>
                <Form.Field
                  id="email"
                  control={Input}
                  label="Email"
                  placeholder="Email"
                  width="16"
                  onChange={handleChange}
                  error={errors.email}
                  style={classes.emailField}
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Field
                  id="password"
                  control={Input}
                  label="Password"
                  placeholder="Password"
                  type="Password"
                  width="16"
                  onChange={handleChange}
                  error={errors.password}
                  required
                />
              </Form.Group>

              {isVerificationFailed && (
                <div style={classes.verifyText}>Please Verify Yourself</div>
              )}

              <Form.Group style={classes.recaptchaContainer}>
                <ReCaptcha recaptchaRef={recaptchaRef} />
              </Form.Group>

              <Form.Group style={classes.buttonContainer}>
                <Form.Button
                  disabled={isLoading}
                  loading={isLoading}
                  color={"orange"}
                >
                  Sign In
                </Form.Button>
              </Form.Group>
              <div style={classes.forgotPassword}>
                <a onClick={goToForgotPassword}>Forgot Password?</a>
              </div>
            </Form>
          </Container>
        </Card>
      </div>
    </div>
  );
}

export default Login;
