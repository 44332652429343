import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { BACKEND_URL } from "../../Config";
import { indexStyles } from "./styles";
import { Header, Input, Form } from "semantic-ui-react";

function ActiveScans(props) {
  const { setScanId } = props;
  const { setScanStarted } = props;
  const { setScanError } = props;

  const isMobile = useMediaQuery({ query: "(max-width: 750px)" });
  const classes = indexStyles(isMobile);
  const history = useHistory();

  const [url, setUrl] = useState("");
  const [urlError, setUrlError] = useState(false);

  const handleChange = (e) => {
    setUrl(e.target.value);
    setUrlError(false);
  };

  const handleSubmit = async () => {
    if (
      !/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        url
      )
    ) {
      setUrlError(true);
      return;
    }

    if (url === "") return;

    try {
      const response = await axios.post(`${BACKEND_URL}/activeScans/add`, {
        url,
      });
      if (response.data.success) {
        if (response.data.alreadyExists) {
          history.push(
            `/search-results?type=${"activeScan"}&reportId=${
              response.data.scanId
            }`
          );
        } else {
          setScanStarted(true);
          setScanId(response.data.scanId);
        }
      }
    } catch (error) {
      if (error.response?.status === 400 && error.response?.data.errors?.url) {
        setUrlError(true);
        return;
      }
      setScanError("Something went wrong. Please try again later.");
    }
  };

  const displayError = () => {
    if (urlError) {
      return (
        <div style={classes.errorTextContainer}>
          <p>Please enter a valid url.</p>
        </div>
      );
    }
  };

  return (
    <div style={classes.container}>
      <div style={classes.lookupHeader}>
        <Header as="h3">Lookup Domain Now</Header>
      </div>
      <Form onSubmit={handleSubmit} style={classes.form}>
        <Input
          action={{
            content: isMobile ? null : "Scan",
            icon: isMobile ? "search" : null,
            // onClick: handleSubmit,
          }}
          style={classes.urlInput}
          placeholder="Enter url..."
          defaultValue={url}
          onChange={handleChange}
        />
      </Form>
      {displayError()}
    </div>
  );
}

export default ActiveScans;
