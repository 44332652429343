export const indexStyles = (isMobile) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "50px",
  },
  formConainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "100px",
  },
  progressTextContainer: {
    width: "70%",
    fontSize: isMobile ? "16px" : "20px",
    marginBottom: "20px",
  },
  progress: { width: "70%" },
  card: { maxWidth: "500px", marginBottom: "50px" },
  form: { margin: "20px" },
  checkbox: { margin: "15px 0px 15px 0px" },
  verifyText: {
    color: "#B03060",
  },
  recaptchaContainer: {
    paddingLeft: "7px",
  },
  addReportContainer: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
});
