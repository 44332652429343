import { colors } from "../../Themes";
import styled from "styled-components";
import { Button } from "semantic-ui-react";

export const SignInButton = styled(Button)`
  color: ${colors.orange} !important;
  background-color: transparent !important;
  border: 1px solid ${colors.orange} !important;
  transition: 0.3s;
  maxwidth: "80%";
  &:hover {
    color: #fff !important;
    background-color: ${colors.orange} !important;
    transition: 0.3s;
  }
`;

export const indexStyles = (passwordStrength) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "50px",
    marginBottom: "50px",
  },
  card: { maxWidth: "500px" },
  form: { margin: "20px", paddingBottom: "20px" },
  textField: { marginBottom: "10px" },
  progressContainer: { width: "100%" },
  textContainerOuter: {
    width: `${passwordStrength.value}%`,
    height: "20px",
    backgroundColor: `${passwordStrength.color}`,
  },
  textContainerInner: { fontSize: "13px" },
  verifyText: {
    color: "#B03060",
  },
  recaptchaContainer: {
    paddingLeft: "7px",
  },
  signupBtnContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "40px",
    marginBottom: "-5px",
  },
});

export const confirmation = (isMobile) => ({
  grid: { height: "80vh" },
  loadingContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  verifiedContainerOuter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: isMobile ? "0px 20px" : "0px",
  },
  verfiedContainerInner: {
    display: "flex",
    flexDirection: isMobile ? "column" : "",
    justifyContent: "center",
  },
  verifiedTextContainer: {
    height: "100%",
    fontSize: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    color: colors.greyBlack,
  },
  verifiedTitle: { marginBottom: "0px" },
  verifiedSubTitle: { fontSize: "18px" },
  notVerifiedContainerOuter: {
    display: "flex",
    justifyContent: "center",
    flexDirection: isMobile ? "column" : "",
  },
  errorTextContainer: {
    height: "100%",
    fontSize: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    color: colors.greyBlack,
  },
  errorTitle: {
    marginBottom: "0px",
  },
  errorMsg: { fontSize: "18px" },
});
