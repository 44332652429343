import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Menu, Icon, Divider, Dropdown } from "semantic-ui-react";
import { Image } from "semantic-ui-react";
import MainSidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { logOutUser } from "../../../store/actions/authActions";
import { useMediaQuery } from "react-responsive";
import SearchBar from "./SearchBar";
import Avatar from "react-avatar";
import Logo from "../../../images/URLAbuseLogo.svg";
import { responsiveSmallStyles } from "./styles";
import { SignInButton, SignUpButton } from "./styles";

function ResponsiveSmall() {
  const classes = responsiveSmallStyles();

  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  let location = useLocation();

  const { isAuthenticated } = useSelector((state) => state.auth);
  const { userData } = useSelector((state) => state.auth);
  const { userAuthData } = useSelector((state) => state.auth);

  const [isVisible, setisVisible] = useState(false); // Sidebar
  const [searchVisible, setSearchVisible] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const setVisible = (visibility) => {
    setisVisible(visibility);
  };

  const showSearchBar = () => {
    setSearchVisible((state) => !state);
  };

  const goToLogin = () => {
    history.push("/login");
  };

  const goToSignUp = () => {
    history.push("register");
  };

  const goToHome = (e) => {
    if (location.pathname === "/") {
      window.location.reload(true);
      return;
    }
    history.push("/");
  };

  const goToProfile = () => {
    history.push("/info/summary");
  };

  const logOut = () => {
    logOutUser()(dispatch);
  };

  const returnPosition = () => {
    if (isMobile) {
      return "right";
    }
  };

  const trigger = (
    <div>
      <Avatar
        color="#f28057"
        name={`${userData.firstName}`}
        size={"30px"}
        round
      />
    </div>
  );

  return (
    <>
      <Menu secondary style={classes.menu}>
        {searchVisible && (
          <>
            <Menu.Item style={classes.menuItemSearchVisible}>
              <div style={classes.searchBarContainer}>
                <SearchBar></SearchBar>
              </div>
              <Icon
                name="close"
                onClick={showSearchBar}
                style={classes.closeIcon}
              />
            </Menu.Item>
          </>
        )}

        {!searchVisible && (
          <>
            <Menu.Item style={classes.menuItemSideBarIcon}>
              {isVisible ? (
                <Icon
                  name="close"
                  onClick={() => setVisible(false)}
                  style={classes.sidebarCloseIcon}
                />
              ) : (
                <Icon
                  name="bars"
                  onClick={() => setVisible(true)}
                  style={classes.sidebarMenuIcon}
                />
              )}
            </Menu.Item>
            <div onClick={goToHome} style={classes.logoContainer}>
              <Image src={Logo} style={classes.logo} />
            </div>

            {!isMobile && (
              <Menu.Item style={classes.menuItemSearchBar}>
                <div style={classes.searchBarContainer}>
                  <SearchBar></SearchBar>
                </div>
              </Menu.Item>
            )}

            {isAuthenticated ? (
              <>
                <Menu.Item position="right"></Menu.Item>

                {isMobile && (
                  <Menu.Item position="right" style={classes.menuItemSearch}>
                    <Icon
                      name={"search"}
                      onClick={showSearchBar}
                      style={classes.searchIcon}
                    />
                  </Menu.Item>
                )}

                <Dropdown item style={classes.dropdown} trigger={trigger}>
                  <Dropdown.Menu style={classes.dropdownMenu} direction="left">
                    <Dropdown.Item
                      onClick={goToProfile}
                      style={classes.dropdownItemProfile}
                    >
                      <div>
                        <div style={classes.profileText}>Profile</div>
                        <div style={classes.emailText}>
                          {userAuthData.email}
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={logOut}
                      style={classes.dropdownItemLogout}
                    >
                      Log out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <>
                <Menu.Item position="right"></Menu.Item>

                {!isMobile && (
                  <>
                    <Menu.Item
                      style={classes.menuItemLogin}
                      position={returnPosition()}
                    >
                      <SignInButton onClick={goToLogin}>Log in</SignInButton>
                    </Menu.Item>

                    <Menu.Item style={classes.menuItemSignup}>
                      <SignUpButton color="orange" onClick={goToSignUp}>
                        Sign up
                      </SignUpButton>
                    </Menu.Item>
                  </>
                )}

                {isMobile && (
                  <Menu.Item
                    position="right"
                    style={classes.notAuthMenuItemSearch}
                  >
                    <Icon
                      name={"search"}
                      onClick={showSearchBar}
                      style={classes.searchIcon}
                    />
                  </Menu.Item>
                )}
              </>
            )}
          </>
        )}
      </Menu>
      <Divider style={classes.divider} />
      <MainSidebar isVisible={isVisible} setVisible={setVisible} />
    </>
  );
}

export default ResponsiveSmall;
