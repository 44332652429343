import React from "react";
import { Button, Segment } from "semantic-ui-react";
import { Icon, List } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { colors } from "../../../Themes";
import styled from "styled-components";
import { blagStyles } from "./styles";

const ViewMoreButton = styled(Button)`
  color: ${colors.orange} !important;
  background-color: transparent !important;
  border: 1px solid ${colors.orange} !important;
  transition: 0.3s;
  &:hover {
    color: #fff !important;
    background-color: ${colors.orange} !important;
    transition: 0.3s;
  }
`;

function Blag() {
  const isTablet = useMediaQuery({ query: "(max-width: 750px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 400px)" });
  const classes = blagStyles(isMobile, isTablet);

  return (
    <div>
      <div style={classes.title}>Detected on BLAG</div>

      <div style={classes.container}>
        <div>
          <Segment style={classes.segment}>
            <List divided verticalAlign="middle">
              <List.Item style={classes.listItem}>
                <List.Content style={classes.listContent}>
                  <div>
                    <span style={classes.listTitle}>Phishing Tank</span>
                  </div>

                  <div style={classes.btnContainer}>
                    <ViewMoreButton size="tiny">
                      View More
                      <Icon name="right arrow" />
                    </ViewMoreButton>
                  </div>
                </List.Content>

                <List.Content></List.Content>
              </List.Item>

              <List.Item style={classes.listItem}>
                <List.Content>
                  <List.Content style={classes.listContent}>
                    <div>
                      <span style={classes.listTitle}>OpenPish</span>
                    </div>
                    <div style={classes.btnContainer}>
                      <ViewMoreButton size="tiny">
                        View More
                        <Icon name="right arrow" />
                      </ViewMoreButton>
                    </div>
                  </List.Content>
                </List.Content>
              </List.Item>
            </List>
          </Segment>
        </div>

        <div style={classes.comingSoon}>
          <p style={classes.comingSoonText}>Coming Soon...</p>
        </div>
      </div>
    </div>
  );
}

export default Blag;
