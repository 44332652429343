import React, { useState } from "react";
import { Button, Modal, Form, Input, Icon, Message } from "semantic-ui-react";
import axios from "axios";
import { BACKEND_URL } from "../../../Config";
import useHandleAuthFail from "../../../utils/useHandleAuthFail";
import { useLocation } from "react-router-dom";
import { newKeyStyles } from "./styles";

function NewKeyModal({ open, setOpen, setApiKeys }) {
  const classes = newKeyStyles();

  const location = useLocation();
  const handleAuthFail = useHandleAuthFail();

  const [description, setDescription] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    description: null,
  });
  const [genericError, setGenericError] = useState("");

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const id = target.id;

    if (errors[id] !== null) {
      setErrors({
        ...errors,
        [id]: null,
      });
    }
    setDescription(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGenericError("");
    setIsLoading(true);

    try {
      const response = await axios.post(`${BACKEND_URL}/apiKeys/generateKey`, {
        description,
      });
      if (response.data.success) {
        setApiKeys(response.data.apiKeys);
        setOpen(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleAuthFail(location.pathname);
        return;
      }

      if (error.response?.data.errors) {
        setErrors(error.response.data.errors);
      } else if (error.response?.data.error) {
        setGenericError(error.response.data.error);
      }
    }
    setIsLoading(false);
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      open={open}
      dimmer={"blurring"}
      size={"small"}
      closeIcon
    >
      <Modal.Header style={classes.modalHeader}>
        <Icon name="key" /> New API Key
      </Modal.Header>
      <Modal.Content>
        {genericError?.length > 0 && <Message error>{genericError}</Message>}
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Field
              id="description"
              label="Description"
              placeholder="Description"
              width="16"
              control={Input}
              onChange={handleChange}
              error={errors.description}
              required
            />
          </Form.Group>

          <p>
            Please add a short description of where and how you will use the API
            key.
            <br />
            This can include whether you're using it with external tools /
            software.
          </p>
          <div style={classes.btnContainer}>
            <Button
              color="orange"
              style={classes.newKeyBtn}
              type="submit"
              disabled={isLoading}
              loading={isLoading}
            >
              <Icon name="add" style={classes.newKeyIcon} />
              Create new API key
            </Button>
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  );
}

export default NewKeyModal;
