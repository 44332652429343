import React, { useState, useEffect, useRef } from "react";
import { Card, Container, Form } from "semantic-ui-react";
import { Header, Input, Message } from "semantic-ui-react";
import ReCaptcha from "../Helpers/ReCaptcha";
import axios from "axios";
import { BACKEND_URL } from "../../Config";
import { colors } from "../../Themes";
import { indexStyles } from "./styles";

function SignUp() {
  const recaptchaRef = useRef();
  const [signUpSuccess, setSignUpSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerificationFailed, setIsVerificationFailed] = useState(false);

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [passwordEntered, setPasswordEntered] = useState(false); // To check if user started typing the PW
  const [passwordStrength, setPasswordStrength] = useState({
    value: 0,
    text: "",
    color: "",
  });
  const classes = indexStyles(passwordStrength);

  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    confirmPassword: null,
  });
  const [genericError, setGenericError] = useState("");

  useEffect(() => {
    if (state.confirmPassword) {
      if (state.confirmPassword !== state.password)
        setErrors({ ...errors, confirmPassword: "Passwords do not match" });
      else setErrors({ ...errors, confirmPassword: null });
    }
  }, [state.confirmPassword]);

  const changePasswordStrength = (pw) => {
    setPasswordEntered(true);
    // 1 lowercase, 1 uppercase, 1 number, 1 special character, length: 8
    var strongPW = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    // 1 lowercase, 1 uppercase, 1 number or 1 special character, length: 6
    var mediumPW1 = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
    var mediumPW2 = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{6,})"
    );

    if (strongPW.test(pw)) {
      setPasswordStrength({
        value: 100,
        text: "Strong",
        color: colors.PWStrong,
      });
    } else if (mediumPW1.test(pw) || mediumPW2.test(pw)) {
      setPasswordStrength({
        value: 66,
        text: "Medium",
        color: colors.PWMedium,
      });
    } else {
      setPasswordStrength({
        value: 33,
        text: "Weak",
        color: colors.PWWeak,
      });
    }
  };

  const handleChange = (e) => {
    const target = e.target;
    const id = target.id;
    const value = target.type === "checkbox" ? target.checked : target.value;

    if (errors[id] !== null) {
      setErrors({
        ...errors,
        [id]: null,
      });
    }

    if (id === "password") {
      changePasswordStrength(value);
    }

    setState({
      ...state,
      [id]: value,
    });
  };

  const submitForm = async (e) => {
    e.preventDefault();

    const recaptchaToken = recaptchaRef.current.getValue();

    // Check Password Strenth
    if (passwordStrength.text === "Weak") {
      setErrors({ ...errors, password: "Passwords is weak" });
      return;
    }

    // Check confirmPassword & password
    if (state.confirmPassword !== state.password) {
      setErrors({ ...errors, confirmPassword: "Passwords do not match" });
      return;
    } else {
      setErrors({ ...errors, confirmPassword: null });
    }

    // Check recaptcha verification
    if (recaptchaToken?.length === 0) {
      setIsVerificationFailed(true);
      return;
    }

    recaptchaRef.current.reset();
    setIsLoading(true);
    setGenericError("");
    setSignUpSuccess(false);
    setIsVerificationFailed(false);

    try {
      const response = await axios.post(`${BACKEND_URL}/users/register`, {
        ...state,
        recaptchaToken,
      });
      if (response.data.success) {
        setSignUpSuccess(true);
        setState({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassword: "",
        });
        setIsLoading(false);
        setPasswordEntered(false);
      }
    } catch (error) {
      setIsLoading(false);
      setSignUpSuccess(false);
      if (error.response?.data.errors) {
        setErrors(error.response.data.errors);
      } else if (error.response?.data.error) {
        setGenericError(error.response.data.error);
      }
    }
  };

  const displaySuccessMessage = () => {
    if (signUpSuccess) {
      return (
        <Message
          success
          header="Registration was successful."
          content="A verification link has been sent to your email account."
        />
      );
    } else if (signUpSuccess === false && genericError?.length > 0) {
      return <Message error content={genericError} />;
    }
  };

  return (
    <div>
      <Container style={classes.container}>
        {displaySuccessMessage()}
        <Card fluid style={classes.card}>
          <Container>
            <Form style={classes.form} onSubmit={submitForm}>
              <Header as="h2" textAlign="center">
                Sign Up
              </Header>

              <Form.Group>
                <Form.Field
                  id="firstName"
                  control={Input}
                  label="First Name"
                  placeholder="First Name"
                  value={state.firstName}
                  width="8"
                  onChange={handleChange}
                  error={errors.firstName}
                  style={classes.textField}
                  required
                />
                <Form.Field
                  id="lastName"
                  control={Input}
                  label="Last Name"
                  placeholder="Last Name"
                  value={state.lastName}
                  width="8"
                  onChange={handleChange}
                  error={errors.lastName}
                  style={classes.textField}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  id="email"
                  control={Input}
                  label="Email"
                  placeholder="Email"
                  value={state.email}
                  width="16"
                  onChange={handleChange}
                  error={errors.email}
                  style={classes.textField}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  id="password"
                  control={Input}
                  label="Password"
                  placeholder="Password"
                  value={state.password}
                  type="Password"
                  width="16"
                  onChange={handleChange}
                  error={errors.password}
                  style={classes.textField}
                  required
                />
              </Form.Group>

              {passwordEntered && (
                <div className="ui progress" style={classes.progressContainer}>
                  <div className="bar" style={classes.textContainerOuter}>
                    <div
                      className="progress progress-text"
                      style={classes.textContainerInner}
                    >
                      {passwordStrength.text}
                    </div>
                  </div>
                </div>
              )}

              <Form.Group>
                <Form.Field
                  id="confirmPassword"
                  control={Input}
                  label="Confirm Password"
                  placeholder="Password"
                  value={state.confirmPassword}
                  type="Password"
                  width="16"
                  onChange={handleChange}
                  error={errors.confirmPassword}
                  style={classes.textField}
                  required
                />
              </Form.Group>

              {isVerificationFailed && (
                <div style={classes.verifyText}>Please Verify Yourself</div>
              )}

              <Form.Group style={classes.recaptchaContainer}>
                <ReCaptcha recaptchaRef={recaptchaRef} />
              </Form.Group>

              <Form.Group style={classes.signupBtnContainer}>
                <Form.Button
                  disabled={isLoading}
                  loading={isLoading}
                  color={"orange"}
                >
                  Sign Up
                </Form.Button>
              </Form.Group>
            </Form>
          </Container>
        </Card>
      </Container>
    </div>
  );
}

export default SignUp;
