import React, { useState, useEffect } from "react";
import { Container, Grid, Progress } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { indexStyles } from "./styles";
import { io } from "socket.io-client";
import { BACKEND_URL } from "../../Config";

function ScanProgress(props) {
  const { scanId, scanError } = props;
  const isMobile = useMediaQuery({ query: "(max-width: 750px)" });
  const classes = indexStyles(isMobile);
  const history = useHistory();

  const [isProcessing, setIsProcessing] = useState(true);
  const [progress, setProgress] = useState(0);
  const [progressError, setProgressError] = useState("");

  useEffect(() => {
    updateProgress();
  }, [scanId]);

  useEffect(() => {
    return () => {
      setIsProcessing(false);
    };
  }, []);

  const updateProgress = () => {
    if (!scanId) return;

    const socket = io(BACKEND_URL);
    socket.on("connect", () => {
      socket.emit("new_scan_in_progress", scanId);

      socket.on("scan_progress", (state) => {
        setProgress(Math.ceil(state * 20));
      });

      socket.on("scan_process_finished", (report) => {
        socket.emit("update_reports_table");
        socket.disconnect();
        history.push(
          `/search-results?type=${"activeScan"}&reportId=${report._id}`
        );
      });

      socket.on("process_error", (errMsg) => {
        setIsProcessing(false);
        setProgressError(errMsg);
        setProgress(0);
        socket.disconnect();
      });
    });
  };

  const displayResults = () => {
    if (isProcessing) {
      return (
        <Container style={classes.processingContainer}>
          <div style={classes.progressTextContainer}>
            <p>We are processing your scan report. Please wait . . . </p>
          </div>
          <div style={classes.progressContainer}>
            <Progress percent={progress} color="orange" />
          </div>
        </Container>
      );
    } else if (scanError?.length || progressError?.length) {
      return (
        <div>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={2} computer={1} />
              <Grid.Column mobile={16} tablet={12} computer={14}>
                <p style={classes.errorText}>
                  {scanError?.length > 0 ? scanError : ""}
                  {progressError?.length > 0 ? progressError : ""}
                </p>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={2} computer={1} />
            </Grid.Row>
          </Grid>
        </div>
      );
    }
  };

  return <div>{displayResults()}</div>;
}

export default ScanProgress;
