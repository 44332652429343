import React, { useState, useEffect } from "react";
import { Card, Container, Form } from "semantic-ui-react";
import { Header, Input, Message } from "semantic-ui-react";
import axios from "axios";
import { BACKEND_URL } from "../../Config";
import { useHistory } from "react-router-dom";
import { colors } from "../../Themes";
import { useMediaQuery } from "react-responsive";
import { resetPasswordForm } from "./styles";

function ResetPasswordForm({ userId, otp }) {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [state, setstate] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const [genericError, setGenericError] = useState("");
  const [errors, setErrors] = useState({
    newPassword: null,
    confirmNewPassword: null,
  });
  const [passwordEntered, setPasswordEntered] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    value: 0,
    text: "",
    color: "",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const classes = resetPasswordForm(isMobile, passwordStrength);

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setstate({
        newPassword: "",
        confirmNewPassword: "",
      });
      setPasswordEntered(false);
    };
  }, []);

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const id = target.id;

    if (errors[id] !== null) {
      setErrors({
        ...errors,
        [id]: null,
      });
    }

    if (id === "newPassword") {
      changePasswordStrength(value);
    }

    setstate({
      ...state,
      [id]: value,
    });
  };

  const changePasswordStrength = (pw) => {
    setPasswordEntered(true);
    // 1 lowercase, 1 uppercase, 1 number, 1 special character, length: 8
    var strongPW = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    // 1 lowercase, 1 uppercase, 1 number or 1 special character, length: 6
    var mediumPW1 = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
    var mediumPW2 = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{6,})"
    );

    if (strongPW.test(pw)) {
      setPasswordStrength({
        value: 100,
        text: "Strong",
        color: colors.PWStrong,
      });
    } else if (mediumPW1.test(pw) || mediumPW2.test(pw)) {
      setPasswordStrength({
        value: 66,
        text: "Medium",
        color: colors.PWMedium,
      });
    } else {
      setPasswordStrength({
        value: 33,
        text: "Weak",
        color: colors.PWWeak,
      });
    }
  };

  const displayMessage = () => {
    if (genericError?.length > 0) {
      return <Message style={classes.message} error content={genericError} />;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!otp || !userId) return;
    
    if (state.newPassword !== state.confirmNewPassword) {
      setErrors({ ...errors, confirmNewPassword: "Passwords do not match" });
      return;
    }

    if (passwordStrength.text === "Weak") {
      setErrors({
        ...errors,
        newPassword: `Password is weak. Please enter a stronger password.
        You can include lower and uppercase letters, numbers and special characters.`,
      });
      return;
    }

    setGenericError("");
    setIsLoading(true);
    try {
      const response = await axios.post(`${BACKEND_URL}/users/resetPassword`, {
        ...state,
        otp,
        userId,
      });
      if (response.data.success) {
        history.push({
          pathname: "/login",
          state: { resetSuccessMsg: "Password was reset." },
        });
      }
    } catch (error) {
      if (error.response?.data.error) {
        setGenericError(error.response.data.error);
      } else if (error.response?.data.errors) {
        setErrors(error.response.data.errors);
      }
      setIsLoading(false);
    }
  };

  return (
    <div style={classes.wrapper}>
      {displayMessage()}
      <Card style={classes.card}>
        <Container>
          <Form style={classes.form} onSubmit={handleSubmit}>
            <Header as="h3" textAlign="center" style={classes.header}>
              Reset Password
            </Header>

            <Form.Group style={classes.newPassword}>
              <Form.Field
                id="newPassword"
                control={Input}
                label="New Password"
                placeholder="New Password"
                type="Password"
                width="16"
                onChange={handleChange}
                error={errors.newPassword}
                // required
              />
            </Form.Group>

            {passwordEntered && (
              <div className="ui progress" style={classes.progressContainer}>
                <div className="bar" style={classes.textContainerOuter}>
                  <div
                    className="progress progress-text"
                    style={classes.textContainerInner}
                  >
                    {passwordStrength.text}
                  </div>
                </div>
              </div>
            )}

            <Form.Group>
              <Form.Field
                id="confirmNewPassword"
                control={Input}
                label="Confirm New Password"
                placeholder="Confirm New Password"
                type="Password"
                width="16"
                onChange={handleChange}
                error={errors.confirmNewPassword}
                // required
              />
            </Form.Group>

            <Form.Group style={classes.btnContainer}>
              <Form.Button
                disabled={isLoading}
                loading={isLoading}
                color={"orange"}
              >
                Change Password
              </Form.Button>
            </Form.Group>
          </Form>
        </Container>
      </Card>
    </div>
  );
}

export default ResetPasswordForm;
