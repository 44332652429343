import { colors } from "../../Themes";

export const sortedTableStyles = (isMobile) => ({
  table: { width: isMobile ? "96%" : "100%", margin: "auto", marginTop: "0px" },
  urlHeader: { borderRight: "0px" },
  ageHeader: { borderLeft: "0px", borderRight: "0px" },
  typeHeader: { borderLeft: "0px", borderRight: "0px" },
  countryHeader: { borderLeft: "0px", textAlign: "center" },
  urlCell: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  ageCell: { color: colors.greyBlack },
});

export const lineChartStyles = () => ({
  container: { width: "100%", marginBottom: "50px" },
  colors: {
    phishing: "#36a2eb",
    spam: "#f66183",
    other: "#4bc0c0",
    scan: "#ed8845",
  },
});
