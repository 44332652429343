import { colors } from "../../../Themes";

export const indexStyles = () => ({
  loadingContainer: { marginTop: "100px" },
  notAvailableText: { fontSize: "18px", color: colors.greyBlack },
  notAvailableIcon: { fontSize: "15px" },
  reportGrid: {
    backgroundColor: colors.white,
    paddingTop: "25px",
    paddingBottom: "25px",
  },
  typeRow: { marginBottom: "10px" },
  typeContainer: { fontSize: "20px" },
  typeLabel: { marginLeft: "10px" },
  ipRow: { marginBottom: "5px" },
  ipText: { color: colors.orange, fontWeight: "600" },
  abuseTypeRow: { marginBottom: "5px" },
  abuseTypeText: { fontWeight: "700" },
  urlText: { fontSize: "12px" },
  url: { color: colors.orange, fontSize: "14px" },
  submission: { fontSize: "12px" },
  submissionDate: { fontSize: "13px" },
  screenshotGrid: {
    backgroundColor: colors.bgGrey,
    paddingTop: "25px",
    paddingBottom: "25px",
  },
  tableGrid: {
    paddingTop: "25px",
    paddingBottom: "25px",
    backgroundColor: colors.white,
  },
  blagGrid: {
    paddingTop: "25px",
    paddingBottom: "25px",
    backgroundColor: colors.bgGrey,
  },
});

export const tabScreenshotStyles = (isTablet) => ({
  title: {
    fontSize: "18px",
    fontWeight: "700",
    marginTop: "10px",
  },
  segment: {
    width: "100%",
    padding: "20px",
    fontSize: "13px",
    minHeight: isTablet ? "20px" : "341px",
  },
  descriptionTitle: {
    fontSize: isTablet ? "15px" : "16px",
    fontWeight: "700",
    marginBottom: "6px",
  },
  divider:{
    marginTop: "0px"
  },
  description:{
    marginBottom: "15px",
  },
  urlContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: isTablet ? "column" : "row",
  },
  url: { fontWeight: "600", color: colors.orange },
  summaryBtnContainer: { marginTop: isTablet ? "10px" : "" },
  summaryBtn: { marginRight: "5px" },
  liveInfoContainer:{
    marginTop: "10px",
  },
  liveInfo: {
    fontSize: isTablet ? "15px" : "16px",
    fontWeight: "700",
    marginBottom: "6px",
  },
  infoItemContainer: { fontSize: "14px", marginBottom: "5px" },
  infoTitle: { fontWeight: "600" },
  checkIcon: { marginLeft: "5px" },
  classification: { color: "grey" },
  infoDns: { fontSize: "14px", marginBottom: "20px" },
  screenshotTitle: {
    fontSize: "18px",
    fontWeight: "700",
    marginTop: "10px",
    marginBottom: "14px",
  },
  screenshot: { marginTop: "8px" },
  noScreenshotSegment: {
    minHeight: "230px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  textContainer: {
    textAlign: "center",
    fontSize: "15px",
  },
  errorIcon: { marginLeft: "5px" },
});

export const statisticStyles = () => ({
  container: { height: "100%" },
  statTitle: {
    fontSize: "16px",
    fontWeight: "700",
    marginBottom: "15px",
  },
});

export const blagStyles = (isMobile, isTablet) => ({
  title: {
    fontSize: "16px",
    marginTop: "20px",
    fontWeight: "700",
    marginBottom: "10px",
  },
  container: { position: "relative", width: "100%" },
  segment: {
    width: isTablet ? "100%" : "60%",
    paddingTop: "0px",
    paddingBottom: "0px",
    opacity: "0.5", // Coming soon
  },
  listItem: { padding: "15px" },
  listContent: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
  },
  listTitle: {
    fontSize: isMobile ? "14px" : "16px",
    fontWeight: "600",
    color: colors.greyBlack,
    marginRight: "5px",
  },
  btnContainer: { marginTop: isMobile ? "10px" : "" },
  comingSoon: {
    // Coming soon
    position: "absolute",
    top: "0px",
    left: "0px",
    bottom: "0px",
    width: isTablet ? "100%" : "60%",
    background: "rgb(125, 125, 125, 0.1)",
    color: "#000",
    zIndex: "100",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  comingSoonText: { fontSize: "20px", color: "#000" }, // Coming soon
});
