import { colors } from "../../../Themes";

export const indexStyles = (isMobile) => ({
  errorText: { fontSize: "18px", color: colors.greyBlack },
  errorIcon: { fontSize: "15px" },
  reportContainer: isMobile
    ? { paddingLeft: "15px", paddingRight: "15px" }
    : {},
  breadCrumb: { marginTop: "20px" },
  homeLink: { color: "#777" },
  breadcrumbDivider: { color: "#777", marginLeft: "5px", marginRight: "5px" },
  searchResultsSection: { color: "#777" },
  searchResults: { cursor: "pointer" },
  timelineSection: { color: colors.orange },
  typeHeader: { color: colors.greyBlack },
  segment: {
    marginTop: "1px",
  },
  timeAgo: { color: "#8c8c8c" },
  feedContainer: { color: colors.greyBlack },
  feedLabel: { color: colors.linkBlue, fontWeight: "600" },
  feedSummary: {
    fontWeight: "400",
    marginTop: "10px",
  },
  pageContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    marginBottom: "30px",
  },
});
