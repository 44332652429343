import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { Container, TextInput } from "./styles";
import { searchBarStyles } from "./styles";

function SearchBar() {
  const classes = searchBarStyles();

  const [value, setValue] = useState("");
  const history = useHistory();

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (value !== "") {
      history.push(`/search?q=${value.replace(".", "%2e")}`);
    }
  };

  return (
    <div style={classes.formContainer}>
      <form onSubmit={handleSubmit}>
        <div style={classes.container}>
          <Container>
            <Icon style={classes.searchIcon} name="search" />
            <TextInput onChange={handleChange} />
          </Container>
        </div>
      </form>
    </div>
  );
}

export default SearchBar;
