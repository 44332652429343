import React from "react";
import { Icon, Tab, Table } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";

function DetailTable({ report }) {
  const isTablet = useMediaQuery({ query: "(max-width: 750px)" });

  const panes = [
    {
      menuItem: "A",
      render: () => (
        <TabPane>
          <Table unstackable striped>
            {tableHeader("Class", "IP Address", "TTL")}
            <Table.Body>{A_records()}</Table.Body>
          </Table>
        </TabPane>
      ),
    },
    {
      menuItem: "AAAA",
      render: () => (
        <TabPane>
          <Table unstackable striped>
            {tableHeader("Class", "IP Address", "TTL")}
            <Table.Body>{AAAA_records()}</Table.Body>
          </Table>
        </TabPane>
      ),
    },
    {
      menuItem: "NS",
      render: () => (
        <TabPane>
          <Table unstackable striped>
            {tableHeader("Class", "Domain", "TTL")}
            <Table.Body>{NS_records()}</Table.Body>
          </Table>
        </TabPane>
      ),
    },
    {
      menuItem: "MX",
      render: () => (
        <TabPane>
          <Table unstackable striped>
            {!isTablet
              ? tableHeader("Class", "Domain", "Preference", "TTL", true, true)
              : tableHeader("Class", "Domain", "Preference", true, true)}
            <Table.Body>{MX_records()}</Table.Body>
          </Table>
        </TabPane>
      ),
    },
    {
      menuItem: "CNAME",
      render: () => (
        <TabPane>
          <Table unstackable striped>
            {tableHeader("Class", "IP Address", "TTL")}
            <Table.Body>{CNAME_records()}</Table.Body>
          </Table>
        </TabPane>
      ),
    },
  ];

  const A_records = () => {
    if (report.A && report.A.length > 0) {
      return report.A.map((i) => (
        <Table.Row key={i.answer}>
          <Table.Cell>{i.class}</Table.Cell>
          <Table.Cell>{i.answer}</Table.Cell>
          <Table.Cell>{i.ttl}</Table.Cell>
        </Table.Row>
      ));
    } else {
      return noInfo();
    }
  };

  const AAAA_records = () => {
    if (report.AAAA && report.AAAA.length > 0) {
      return report.AAAA.map((i) => (
        <Table.Row key={i.answer}>
          <Table.Cell>{i.class}</Table.Cell>
          <Table.Cell>{i.answer}</Table.Cell>
          <Table.Cell>{i.ttl}</Table.Cell>
        </Table.Row>
      ));
    } else {
      return noInfo();
    }
  };

  const NS_records = () => {
    if (report.NS && report.NS.length > 0) {
      return report.NS.map((i) => (
        <Table.Row key={i.answer}>
          <Table.Cell>{i.class}</Table.Cell>
          <Table.Cell>{i.answer}</Table.Cell>
          <Table.Cell>{i.ttl}</Table.Cell>
        </Table.Row>
      ));
    } else {
      return noInfo();
    }
  };

  const MX_records = () => {
    if (report.MX && report.MX.length > 0) {
      return report.MX.map((i) => (
        <Table.Row key={i.answer}>
          <Table.Cell>{i.class}</Table.Cell>
          <Table.Cell>{i.answer}</Table.Cell>
          <Table.Cell textAlign="center">{i.preference}</Table.Cell>
          {!isTablet && <Table.Cell textAlign="center">{i.ttl}</Table.Cell>}
        </Table.Row>
      ));
    } else {
      return noInfo(4);
    }
  };

  const CNAME_records = () => {
    if (report.CNAME && report.CNAME.length > 0) {
      return report.CNAME.map((i) => (
        <Table.Row key={i.answer}>
          <Table.Cell>{i.class}</Table.Cell>
          <Table.Cell>{i.answer}</Table.Cell>
          <Table.Cell>{i.ttl}</Table.Cell>
        </Table.Row>
      ));
    } else {
      return noInfo();
    }
  };

  const noInfo = (noColumns = 3) => {
    return (
      <Table.Row>
        <Table.Cell style={{ textAlign: "center" }} colSpan={noColumns}>
          <Icon
            color="orange"
            name="exclamation triangle"
            style={{ marginRight: "5px" }}
          />
          Information Not Available
        </Table.Cell>
      </Table.Row>
    );
  };

  const TabPane = ({ children }) => {
    return (
      <Tab.Pane
        style={{
          padding: isTablet ? "" : "30px 40px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {children}
      </Tab.Pane>
    );
  };

  // pass column names as parameters
  const tableHeader = (
    col1 = "",
    col2 = "",
    col3 = "",
    col4 = "",
    col3TextAlign = false,
    col4TextAlign = false
  ) => {
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{col1}</Table.HeaderCell>
          <Table.HeaderCell>{col2}</Table.HeaderCell>
          <Table.HeaderCell textAlign={col3TextAlign ? "center" : "left"}>
            {col3}
          </Table.HeaderCell>
          {col4?.length > 0 && (
            <Table.HeaderCell textAlign={col4TextAlign ? "center" : "left"}>
              {col4}
            </Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>
    );
  };

  return (
    <div>
      <div style={{ fontSize: "16px", fontWeight: "700" }}>
        Domain & IP Information
      </div>
      <Tab
        panes={panes}
        menu={{ color: "orange", secondary: true, pointing: true }}
        style={{ marginTop: "15px" }}
      />
    </div>
  );
}

export default DetailTable;
