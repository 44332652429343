import React, { useState, useEffect } from "react";
import axios from "axios";
import { BACKEND_URL } from "../../Config";
import Loading from "../Lotties/loading.json";
import Lottie from "react-lottie";
import { useParams } from "react-router-dom";
import Error from "../Lotties/error.json";
import { useMediaQuery } from "react-responsive";
import ResetPasswordForm from "./ResetPasswordForm";
import { resetPassword } from "./styles";

function ResetPassword() {
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const classes = resetPassword(isMobile);

  const { otp, userId } = useParams();
  const [isVerified, setIsVerified] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    title: "",
    msg: "",
  });

  useEffect(() => {
    verificationProcess();
  }, [otp, userId]);

  const verificationProcess = async () => {
    if (!otp || !userId) return;

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BACKEND_URL}/users/resetPassword/confirmation`,
        {
          otp,
          userId,
        }
      );

      if (response.data.success) {
        setIsVerified(true);
      }
    } catch (error) {
      if (error.response?.data.errorObj) {
        setError(error.response.data.errorObj);
      } else if (error.response?.data.error) {
        setError({
          title: error.response.data.error,
        });
      }
      setIsVerified(false);
    }
    setIsLoading(false);
  };

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: Error,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const displayContent = () => {
    if (isLoading) {
      return (
        <div style={classes.loadingContainer}>
          <Lottie options={loadingOptions} height={150} width={150} />
        </div>
      );
    } else {
      if (isVerified === true) {
        return <ResetPasswordForm userId={userId} otp={otp} />;
      } else if (isVerified === false) {
        return (
          <div style={classes.errorContainerOuter}>
            <div style={classes.errorContainerInner}>
              <div>
                <Lottie options={errorOptions} height={150} width={150} />
              </div>
              <div>
                <div style={classes.errorTextContainer}>
                  <p style={classes.errorTitle}>{error.title}</p>
                  <p style={classes.errorMsg}>{error.msg}</p>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  };

  return <div style={classes.wrapper}>{displayContent()}</div>;
}

export default ResetPassword;
