import React, { useState, useEffect } from "react";
import { Table, Label } from "semantic-ui-react";
import axios from "axios";
import { BACKEND_URL } from "../../../Config";
import Lottie from "react-lottie";
import LoadingCircle from "../../Lotties/loadingCircle.json";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import useHandleAuthFail from "../../../utils/useHandleAuthFail";
import { indexStyles } from "./styles";

function ProfileHome() {
  const isMobile = useMediaQuery({ query: "(max-width: 750px)" });
  const tableDataHidden = useMediaQuery({ query: "(max-width: 500px)" });
  const classes = indexStyles(isMobile);
  const location = useLocation();
  const handleAuthFail = useHandleAuthFail();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [data, setData] = useState({
    perMinute: 0,
    perHour: 0,
    perDay: 0,
  });

  const [dataPercentage, setDataPercentage] = useState({
    perMinute: 0,
    perHour: 0,
    perDay: 0,
  });
  const [limits, setLimits] = useState({
    perMinuteLimit: 0,
    perHourLimit: 0,
    perDayLimit: 0,
  });

  useEffect(() => {
    retrieveReportInfo();
  }, []);

  const retrieveReportInfo = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${BACKEND_URL}/reports/user`);
      if (response.data.success) {
        setIsError(false);
        setData(response.data.data);
        setDataPercentage(response.data.dataPercentage);
        setLimits(response.data.limits);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleAuthFail(location.pathname);
        return;
      }
      setIsLoading(false);
      setIsError(true);
    }
  };

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingCircle,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const displayReportData = () => {
    if (isLoading) {
      return (
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan="16">
              <Lottie options={loadingOptions} height={40} width={40} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      );
    } else if (isError) {
      return (
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan="16">
              <p style={classes.errorMsg}>
                Something went wrong. Please try again later.
              </p>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      );
    } else {
      return (
        <Table.Body>
          <Table.Row>
            <Table.Cell>Submitted Reports</Table.Cell>
            <Table.Cell>
              {data.perMinute}/{limits.perMinuteLimit}
              {!tableDataHidden && (
                <span style={classes.percentage}>
                  ( {dataPercentage.perMinute}% )
                </span>
              )}
            </Table.Cell>
            <Table.Cell>
              {data.perHour}/{limits.perHourLimit}
              {!tableDataHidden && (
                <span style={classes.percentage}>
                  ( {dataPercentage.perHour}% )
                </span>
              )}
            </Table.Cell>
            <Table.Cell>
              {data.perDay}/{limits.perDayLimit}
              {!tableDataHidden && (
                <span style={classes.percentage}>
                  ( {dataPercentage.perDay}% )
                </span>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Result Retrieved</Table.Cell>
            <Table.Cell>0/120</Table.Cell>
            <Table.Cell>0/5000</Table.Cell>
            <Table.Cell>0/10000</Table.Cell>
          </Table.Row>
        </Table.Body>
      );
    }
  };

  return (
    <div style={classes.wrapper}>
      <p style={classes.quotasTitle}>Quotas & Rate-Limits</p>

      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>API Limit</Table.HeaderCell>
            <Table.HeaderCell>Per Minute</Table.HeaderCell>
            <Table.HeaderCell>Per Hour</Table.HeaderCell>
            <Table.HeaderCell>Per Day</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {displayReportData()}
      </Table>

      <p style={classes.settingsTitle}>Features & Settings</p>

      <Table unstackable style={classes.table}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Feature</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Report Searchability</Table.Cell>
            <Table.Cell>
              <Label color={"blue"} style={classes.label}>
                Public
              </Label>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Default Report Visbility</Table.Cell>
            <Table.Cell>
              <Label color={"orange"} style={classes.label}>
                Private
              </Label>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
}

export default ProfileHome;
