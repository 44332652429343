import React from "react";
import { Pagination } from "semantic-ui-react";

function PaginationComponent(props) {
  const { itemCount, PAGE_SIZE, activePage, handlePageChange } = props;
  const totalPages = Math.ceil(itemCount / PAGE_SIZE);

  const displayPagination = () => {
    if (itemCount > PAGE_SIZE) {
      return (
        <Pagination
          boundaryRange={0}
          activePage={activePage}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        ></Pagination>
      );
    }
  };
  return <div>{displayPagination()}</div>;
}

export default PaginationComponent;
