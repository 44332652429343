import React from "react";
import { Container, Button, Image } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import NotFoundImage from "./images/notFound.svg";
import { indexStyles } from "./styles";

function NotFound() {
  const classes = indexStyles();
  const history = useHistory();

  const goToHome = () => {
    history.push("/");
  };

  return (
    <>
      <Container style={classes.container}>
        <Image src={NotFoundImage} size="medium" />
        <div style={classes.textContainer}>Page Not Found</div>
        <Button color="orange" style={classes.homeBtn} onClick={goToHome}>
          Go to Home Page
        </Button>
      </Container>
    </>
  );
}

export default NotFound;
