import React from "react";
import { Header, Divider } from "semantic-ui-react";
import { termsStyles } from "./styles";

function Terms() {
  const classes = termsStyles();

  return (
    <div style={classes.termsContainerOuter}>
      <div style={classes.termsContainerInner}>
        <div>
          <Header as="h2"> URL Abuse Terms of Service </Header>

          <Header as="h3" style={classes.h3Header}>
            Terms
          </Header>
          <Divider style={classes.divider} />
          <div>
            By accessing the website at{" "}
            <a target="_blank" href="https://urlabuse.webtelescope.com">
              https://urlabuse.webtelescope.com
            </a>{" "}
            , you are agreeing to be bound by these terms of service, all
            applicable laws and regulations, and agree that you are responsible
            for compliance with any applicable local laws. If you do not agree
            with any of these terms, you are prohibited from using or accessing
            this site. The materials contained in this website are protected by
            applicable copyright and trademark law.
          </div>

          <Header as="h3" style={classes.h3Header}>
            Use License
          </Header>
          <Divider style={classes.divider} />
          <div></div>

          <Header as="h3" style={classes.h3Header}>
            Disclaimer
          </Header>
          <Divider style={classes.divider} />
          <div></div>

          <Header as="h3" style={classes.h3Header}>
            Limitations
          </Header>
          <Divider style={classes.divider} />
          <div>
            In no event shall URL Abuse or its suppliers be liable for any
            damages (including, without limitation, damages for loss of data or
            profit, or due to business interruption) arising out of the use or
            inability to use the materials on URL Abuse's website, even if URL
            Abuse or a URL Abuse authorized representative has been notified
            orally or in writing of the possibility of such damage. Because some
            jurisdictions do not allow limitations on implied warranties, or
            limitations of liability for consequential or incidental damages,
            these limitations may not apply to you.
          </div>

          <Header as="h3" style={classes.h3Header}>
            Accuracy of materials
          </Header>
          <Divider style={classes.divider} />
          <div>
            The materials appearing on URL Abuse's website could include
            technical, typographical, or photographic errors. URL Abuse does not
            warrant that any of the materials on its website are accurate,
            complete or current. URL Abuse may make changes to the materials
            contained on its website at any time without notice. However URL
            Abuse does not make any commitment to update the materials.
          </div>

          <Header as="h3" style={classes.h3Header}>
            Links
          </Header>
          <Divider style={classes.divider} />
          <div>
            URL Abuse has not reviewed all of the sites linked to its website
            and is not responsible for the contents of any such linked site. The
            inclusion of any link does not simply endorsement by URL Abuse of
            the site. Use of any such linked website is at the user's own risk.
          </div>

          <Header as="h3" style={classes.h3Header}>
            Modifications
          </Header>
          <Divider style={classes.divider} />
          <div>
            URL Abuse may revise these terms of service for its website at any
            time without notice. By using this website you are agreeing to be
            bound by the then current version of these terms of service.
          </div>

          <Header as="h3" style={classes.h3Header}>
            Governing Law
          </Header>
          <Divider style={classes.divider} />
          <div>
            These terms and conditions are governed by and construed in
            accordance with the laws of Sri Lanka and you irrevocably submit to
            the exclusive jurisdiction of the courts in that State or location.{" "}
            <br />
            <br />
            This policy is effective as of 1 December 2021.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
