import { logOutUser } from "../store/actions/authActions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

function useHandleAuthFail() {
  const dispatch = useDispatch();
  const history = useHistory();
  
  return (pathname) => {
    logOutUser()(dispatch);
    history.push({
      pathname: `/login`,
      state: {
        pathname,
      },
    });
  };
}

export default useHandleAuthFail;
