import React, { useState } from "react";
import { Form, Input, Message, Icon, Header, Segment } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BACKEND_URL } from "../../../Config";
import * as actions from "../../../store/actions/actionTypes";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import useHandleAuthFail from "../../../utils/useHandleAuthFail";
import { useLocation } from "react-router-dom";
import { indexStyles } from "./styles";

const CustomCloseIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

function ChangeInfo() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const classes = indexStyles(isMobile);
  const dispatch = useDispatch();
  const location = useLocation();
  const handleAuthFail = useHandleAuthFail();

  const { userData } = useSelector((state) => state.auth);

  const [state, setState] = useState({
    firstName: userData.firstName,
    lastName: userData.lastName,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(null);
  const [genericError, setGenericError] = useState("");

  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
  });

  const handleChange = (e) => {
    const target = e.target;
    const id = target.id;
    const value = target.type === "checkbox" ? target.checked : target.value;

    if (errors[id] !== null) {
      setErrors({
        ...errors,
        [id]: null,
      });
    }

    setState({
      ...state,
      [id]: value,
    });
  };

  const displayMessage = () => {
    if (updateSuccess) {
      return (
        <div style={classes.msgContainer}>
          <Message success style={classes.message}>
            <div style={classes.msgTitle}>Profile updated successfully!</div>

            <div style={classes.iconContainer}>
              <CustomCloseIcon
                name="close"
                onClick={() => {
                  setUpdateSuccess(null);
                }}
              />
            </div>
          </Message>
        </div>
      );
    } else if (updateSuccess === false && genericError?.length > 0) {
      return (
        <div style={classes.msgContainer}>
          <Message error style={classes.message}>
            <div style={classes.msgTitle}>{genericError}</div>

            <div style={classes.iconContainer}>
              <CustomCloseIcon
                name="close"
                onClick={() => {
                  setGenericError("");
                }}
              />
            </div>
          </Message>
        </div>
      );
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setUpdateSuccess(null);
    setGenericError("");
    try {
      const response = await axios.patch(
        `${BACKEND_URL}/users/updateInfo`,
        state
      );
      if (response.data.success) {
        const encoded = Buffer.from(JSON.stringify(state), "utf8").toString(
          "base64"
        );
        localStorage.setItem("urlAbuseUser", encoded);
        dispatch({
          type: actions.SET_USER_DATA,
          payload: state,
        });
        setUpdateSuccess(true);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleAuthFail(location.pathname);
        return;
      }
      if (error.response?.data.errors) {
        setErrors(error.response.data.errors);
      } else if (error.response?.data.error) {
        setGenericError(error.response.data.error);
      }
      setUpdateSuccess(false);
      setIsLoading(false);
    }
  };

  return (
    <Segment>
      <Header as="h5" style={classes.header}>
        Update Profile
      </Header>
      <div>
        {displayMessage()}
        <Form style={classes.form} onSubmit={submitForm}>
          <Form.Group>
            <Form.Field
              id="firstName"
              control={Input}
              label="First Name"
              placeholder="First Name"
              value={state.firstName}
              width="16"
              onChange={handleChange}
              error={errors.firstName}
              required
              style={classes.firstName}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              id="lastName"
              control={Input}
              label="Last Name"
              placeholder="Last Name"
              value={state.lastName}
              width="16"
              onChange={handleChange}
              error={errors.lastName}
              required
            />
          </Form.Group>

          <Form.Group style={classes.btnContainer}>
            <Form.Button
              disabled={isLoading}
              loading={isLoading}
              color={"orange"}
              style={classes.saveBtn}
              type="submit"
            >
              Save Changes
            </Form.Button>
          </Form.Group>
        </Form>
      </div>
    </Segment>
  );
}

export default ChangeInfo;
