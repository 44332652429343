import React, { useState, useEffect } from "react";
import { Grid, Menu, Segment, Icon } from "semantic-ui-react";
import ProfileHome from "./ProfileHome";
import ProfileInfo from "./ProfileInfo";
import Settings from "./Settings";
import Help from "./Help";
import ContactUs from "./ContactUs/index";
import { useMediaQuery } from "react-responsive";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../auth/PrivateRoute";
import { useHistory, useLocation } from "react-router-dom";
import NotFound from "../NotFound";
import { indexStyles } from "./styles";

function Profile() {
  const isTablet = useMediaQuery({ query: "(max-width: 992px)" });
  const classes = indexStyles(isTablet);
  const [activeItem, setActiveItem] = useState("home");
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/info/summary") setActiveItem("summary");
    if (location.pathname === "/info/profile") setActiveItem("profile");
    if (location.pathname === "/info/settings") setActiveItem("settings");
    if (location.pathname === "/info/help") setActiveItem("help");
    if (location.pathname === "/info/contact-us") setActiveItem("contact");
  }, []);

  const goToSummary = () => {
    setActiveItem("summary");
    history.push("/info/summary");
  };
  const goToInfo = () => {
    setActiveItem("profile");
    history.push("/info/profile");
  };
  const goToSettings = () => {
    setActiveItem("settings");
    history.push("/info/settings");
  };
  const goToHelp = () => {
    setActiveItem("help");
    history.push("/info/help");
  };
  const goToContactUs = () => {
    setActiveItem("contact");
    history.push("/info/contact-us");
  };

  return (
    <div style={classes.container}>
      <Grid>
        {!isTablet && <Grid.Column mobile={16} tablet={16} computer={2} />}
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={3}
          style={classes.gridColumn}
        >
          <Segment>
            <Menu fluid vertical secondary attached style={classes.menu}>
              <Menu.Item
                name="home"
                active={activeItem === "summary"}
                onClick={goToSummary}
                style={classes.menuItem}
              >
                <Icon name="file alternate" style={classes.icon} />
                {"Summary"}
              </Menu.Item>

              <Menu.Item
                name="profileInfo"
                active={activeItem === "profile"}
                onClick={goToInfo}
                style={classes.menuItem}
              >
                <Icon name="user" style={classes.icon} />
                {" Profile Info "}
              </Menu.Item>

              <Menu.Item
                name="settings"
                active={activeItem === "settings"}
                onClick={goToSettings}
                style={classes.menuItem}
              >
                <Icon name="setting" style={classes.icon} />
                {" Settings & API"}
              </Menu.Item>

              <Menu.Item
                name="help"
                active={activeItem === "help"}
                onClick={goToHelp}
                style={classes.menuItem}
              >
                <Icon name="help circle" style={classes.icon} />
                {" Help "}
              </Menu.Item>

              <Menu.Item
                name="contact"
                active={activeItem === "contact"}
                onClick={goToContactUs}
                style={classes.menuItem}
              >
                <Icon name="phone" style={classes.icon} />
                {" Contact Us "}
              </Menu.Item>
            </Menu>
          </Segment>
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={9}
          style={{
            paddingLeft: isTablet ? "40px" : "",
            paddingRight: isTablet ? "40px" : "",
          }}
        >
          <div>
            <Switch>
              <PrivateRoute path="/info/summary" component={ProfileHome} />
              <PrivateRoute path="/info/profile" component={ProfileInfo} />
              <PrivateRoute path="/info/settings" component={Settings} />
              <PrivateRoute path="/info/help" component={Help} />
              <PrivateRoute path="/info/contact-us" component={ContactUs} />
              <PrivateRoute path="/info/*" component={NotFound} />
            </Switch>
          </div>
        </Grid.Column>
        {!isTablet && <Grid.Column mobile={16} tablet={16} computer={2} />}
      </Grid>
    </div>
  );
}

export default Profile;
