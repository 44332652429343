import React from "react";
import { Button, Modal } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../../store/actions/authActions";

function LogoutModal({ open, setOpen }) {
  const dispatch = useDispatch();

  const handleLogout = () => {
    logOutUser()(dispatch);
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeOnDimmerClick={false}
      dimmer={"blurring"}
      size={"tiny"}
    >
      <Modal.Header>Success! Your password has been changed.</Modal.Header>
      <Modal.Content>
        <p>
          You have to sign in with your new password to complete the process.
        </p>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button onClick={handleLogout} color={"orange"}>
            Log out
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default LogoutModal;
