export const indexStyles = (isTablet) => ({
  container: { marginTop: isTablet ? "25px" : "50px" },
  gridColumn: {
    paddingLeft: isTablet ? "40px" : "",
    paddingRight: isTablet ? "40px" : "",
  },
  menu: { border: "0px" },
  menuItem: { fontSize: "15px", padding: "15px" },
  icon: { float: "left", marginRight: "10px" },
});
