import React from "react";
import { Checkbox, Segment, Header, List } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { filtersStyles } from "./styles";

function Filters({ setFilter, setActivePage }) {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const classes = filtersStyles(isMobile);

  const handleChange = (e, { value, checked }) => {
    setActivePage(1);
    setFilter((state) => ({
      ...state,
      [e.target.id]: checked,
    }));
  };

  return (
    <div style={classes.container}>
      <Segment style={classes.segment}>
        <Header as="h3">Filters</Header>
        <List>
          <List.Item>
            <Checkbox
              label="Abuse Reports"
              id="abuseReport"
              onChange={handleChange}
            />
          </List.Item>
        </List>
        <List>
          <List.Item>
            <Checkbox
              label="Active Scans"
              id="activeScan"
              onChange={handleChange}
            />
          </List.Item>
        </List>
      </Segment>
    </div>
  );
}

export default Filters;
