import React, { useState } from "react";
import { Container, Grid } from "semantic-ui-react";
import { Header } from "semantic-ui-react";
import LineAreaChart from "../UiElements/LineAreaChart";
import SortableTable from "../UiElements/SortableTable";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { indexStyles } from "./styles";
import { AddReportButton, ViewStatisticsButton } from "./styles";
import ActiveScans from "../ActiveScans";
import ScanProgress from "../ActiveScans/ScanProgress";

function Home() {
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const classes = indexStyles(isMobile);
  const history = useHistory();

  const [scanId, setScanId] = useState(null);
  const [scanStarted, setScanStarted] = useState(false);
  const [scanError, setScanError] = useState("");

  const goToAddAbuse = () => {
    history.push("/add-abuse");
  };

  const goToStats = () => {
    history.push("/statistics");
  };

  const displayContent = () => {
    if (scanStarted) {
      return <ScanProgress scanId={scanId} scanError={scanError} />;
    }

    return (
      <div>
        <div style={classes.container}>
          <Grid>
            <Grid.Column mobile={16} tablet={2} computer={1} />
            <Grid.Column
              mobile={16}
              tablet={12}
              computer={14}
              style={classes.gridColumn}
            >
              <Container textAlign="center">
                <Header as="h1" style={classes.mainHeader}>
                  Database For URL Abuses
                </Header>
                <Container>
                  <AddReportButton
                    size={isMobile ? "small" : "medium"}
                    style={classes.addAbuseButton}
                    onClick={goToAddAbuse}
                  >
                    Add Abuse Report
                  </AddReportButton>

                  <ViewStatisticsButton
                    size={isMobile ? "small" : "medium"}
                    style={classes.statButton}
                    onClick={goToStats}
                  >
                    View Statistics
                  </ViewStatisticsButton>
                </Container>
              </Container>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={2} computer={1} />
          </Grid>
        </div>

        <div>
          <Grid>
            <Grid.Column mobile={1} tablet={1} computer={2} />
            <Grid.Column mobile={14} tablet={14} computer={12}>
              <ActiveScans
                setScanId={setScanId}
                setScanStarted={setScanStarted}
                setScanError={setScanError}
              />
            </Grid.Column>
            <Grid.Column
              mobile={1}
              tablet={1}
              computer={2}
              style={{ padding: "5px" }}
            />
          </Grid>
        </div>

        <div>
          <Grid>
            <Grid.Column
              mobile={16}
              tablet={1}
              computer={2}
              style={{ padding: "0px" }}
            />
            <Grid.Column mobile={16} tablet={14} computer={12}>
              <div style={classes.tableContainer}>
                <SortableTable />
              </div>

              <Container style={classes.chartContainer}>
                <LineAreaChart />
              </Container>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={1} computer={2} />
          </Grid>
        </div>
      </div>
    );
  };

  return <div>{displayContent()}</div>;
}

export default Home;
