import Home from "./components/Home";
import "semantic-ui-css/semantic.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import setAuthToken from "./utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import * as actions from "./store/actions/actionTypes";
import { logOutUser } from "./store/actions/authActions";
import Addabuse from "./components/AddAbuse";
import SearchResults from "./components/SearchResults/ResultsPage";
import Login from "./components/Login";
import PrivateRoute from "./auth/PrivateRoute";
import AuthRoute from "./auth/AuthRoute";
import SignUp from "./components/SignUp";
import Confirmation from "./components/SignUp/Confirmation";
import NotFound from "./components/NotFound";
import MatchedResults from "./components/SearchResults/MatchedResuslts";
import TimelineReport from "./components/SearchResults/TimelineReport";
import Footer from "./components/Layout/Footer";
import MainNavbar from "./components/Layout/Navbar";
import Profile from "./components/Profile";
import ForgotPassword from "./components/Login/ForgotPassword";
import ResetPassword from "./components/Login/ResetPassword";
import PrivacyPolicy from "./components/Layout/Footer/PrivacyPolicy";
import Terms from "./components/Layout/Footer/Terms";
import Statistics from "./components/Statistics";
import { useHistory } from "react-router-dom";

function App() {
  const dispatch = useDispatch();
  const history = useHistory();

  // Check for token to keep user logged in
  if (localStorage.urlAbuseToken) {
    const token = localStorage.getItem("urlAbuseToken");
    const encodedUserData = localStorage.getItem("urlAbuseUser");

    const decodedToken = jwt_decode(token);
    // Check for expired token, considered in miliseconds
    if (decodedToken.exp * 1000 < Date.now()) {
      logOutUser()(dispatch);
      history.push("/login");
    } else {
      const decodedUserData = JSON.parse(
        Buffer.from(encodedUserData, "base64").toString("utf8")
      );

      setAuthToken(token);
      dispatch({
        type: actions.SET_USER_AUTH_DATA,
        payload: decodedToken,
      });
      dispatch({
        type: actions.SET_USER_DATA,
        payload: decodedUserData,
      });
    }
  }

  return (
    <div className="App">
        <div id="page-container">
          <div id="content-wrap">
              <MainNavbar />
              <Switch>
                <Route exact path="/" component={Home} />
                <AuthRoute exact path="/login" component={Login} />
                <AuthRoute exact path="/register" component={SignUp} />
                <AuthRoute exact path="/forgot-password" component={ForgotPassword} />
                <AuthRoute exact path="/confirmation/:otp/:userId"component={Confirmation}/>
                <AuthRoute exact path="/reset-password/:otp/:userId" component={ResetPassword} />
                <PrivateRoute exact path="/add-abuse" component={Addabuse} />
                <PrivateRoute path="/info" component={Profile} />
                <Route path="/search-results" component={SearchResults} />
                <Route path="/search" component={MatchedResults} />
                <Route path="/timeline" component={TimelineReport} />
                <Route exact path="/privacy" component={PrivacyPolicy} />
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/statistics" component={Statistics} />
                <Route component={NotFound} />
              </Switch>
          </div>
          <footer id="footer">
            <Footer />
          </footer>
        </div>
    </div>
  );
}

export default App;
