import { colors } from "../../../Themes";

export const indexStyles = (isMobile) => ({
  header: { padding: "20px 20px 0px", color: colors.greyBlack },
  form: { margin: "20px", paddingBottom: "20px" },
  firstName: { paddingBottom: isMobile ? "10px" : "" },
  btnContainer: {
    display: "flex",
    justifyContent: isMobile ? "center" : "flex-end",
    marginTop: "40px",
    marginBottom: "-5px",
  },
  saveBtn: { marginRight: "0px" },
  msgContainer: { padding: "0px 20px" },
  message: { display: "flex", justifyContent: "space-between" },
  msgTitle: { height: "100%" },
  iconContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});

export const changePasswordStyles = (isMobile, passwordStrength) => ({
  header: { padding: "20px 20px 0px", color: colors.greyBlack },
  form: { margin: "20px", paddingBottom: "20px" },
  currentPassword: { paddingBottom: isMobile ? "10px" : "" },
  newPassword: { paddingBottom: isMobile ? "10px" : "" },
  progressContaienr: {
    width: `${passwordStrength.value}%`,
    height: "20px",
    backgroundColor: `${passwordStrength.color}`,
  },
  progressText: { fontSize: "13px" },
  btnContainer: {
    display: "flex",
    justifyContent: isMobile ? "center" : "flex-end",
    marginTop: "40px",
    marginBottom: "-5px",
  },
  updateBtn: { marginRight: "0px" },
  msgContainer: { padding: "0px 20px" },
  message: { display: "flex", justifyContent: "space-between" },
  msgTitle: { height: "100%" },
  iconContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});
