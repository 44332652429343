import * as actions from "../actions/actionTypes";
import isEmpty from "is-empty";

const initialState = {
  isAuthenticated: false,
  userAuthData: {},
  userData: {},
};

function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_USER_AUTH_DATA:
      return {
        ...state,
        isAuthenticated: !isEmpty(payload),
        userAuthData: payload,
      };
    case actions.SET_USER_DATA:
      return {
        ...state,
        userData: payload,
      };
    default:
      return state;
  }
}

export default authReducer;
