import { colors } from "../../../Themes";

export const indexStyles = (isMobile) => ({
  divider: {
    width: "100%",
    backgroundColor: colors.bgGrey,
  },
  gridRow: { backgroundColor: colors.white },
  gridColumn: { padding: "0px" },
  textContainer: { marginTop: "14px", paddingBottom: "14px" },
  copyrightWrapper: { textAlign: "center", marginBottom: "5px" },
  webtelescopeText: { color: colors.orange, fontWeight: "600" },
  listContainer: { textAlign: "center" },
  socialList: { marginRight: isMobile ? "" : "10px" },
  socailIcon: { color: "#408bb8" },
  textList: { marginTop: isMobile ? "0px" : "" },
  listItem: { color: colors.orange, fontWeight: "600" },
});

export const privacyStyles = () => ({
  privacyContainerOuter: {
    marginTop: "-14px",
    marginBottom: "-30px",
    paddingTop: "50px",
    paddingBottom: "50px",
    backgroundColor: colors.grey,
  },
  privacyContainerInner: { width: "50%", margin: "auto" },
  textContainer: { marginTop: "20px" },
  h3Header: {
    marginBottom: "10px",
  },
  h4Header: {
    marginTop: "10px",
  },
  divider: {
    marginTop: "0px",
    backgroundColor: colors.bgGrey,
  },
});

export const termsStyles = () => ({
  termsContainerOuter: {
    marginTop: "-14px",
    marginBottom: "-30px",
    paddingTop: "50px",
    paddingBottom: "50px",
    backgroundColor: colors.grey,
  },
  termsContainerInner: { width: "50%", margin: "auto" },
  h3Header: {
    marginBottom: "10px",
  },
  divider: {
    marginTop: "0px",
  },
});
