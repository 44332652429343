import React, { useState } from "react";
import { Form, Input, Header, Segment, Message, Icon } from "semantic-ui-react";
import { colors } from "../../../Themes";
import axios from "axios";
import { BACKEND_URL } from "../../../Config";
import styled from "styled-components";
import LogoutModal from "./LogoutModal";
import setAuthToken from "../../../utils/setAuthToken";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import useHandleAuthFail from "../../../utils/useHandleAuthFail";
import { changePasswordStyles } from "./styles";

const CustomCloseIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

function ChangePassword() {
  const location = useLocation();
  const handleAuthFail = useHandleAuthFail();

  const [passwordInfo, setPasswordInfo] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(null);
  const [open, setOpen] = useState(false);
  const [genericError, setGenericError] = useState("");
  const [errors, setErrors] = useState({
    currentPassword: null,
    newPassword: null,
    confirmNewPassword: null,
  });

  const [passwordEntered, setPasswordEntered] = useState(false); // To check if user started typing the PW
  const [passwordStrength, setPasswordStrength] = useState({
    value: 0,
    text: "",
    color: "",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const classes = changePasswordStyles(isMobile, passwordStrength);

  const handleChange = (e) => {
    const target = e.target;
    const id = target.id;
    const value = target.type === "checkbox" ? target.checked : target.value;

    if (errors[id] !== null) {
      setErrors({
        ...errors,
        [id]: null,
      });
    }

    if (id === "newPassword") {
      changePasswordStrength(value);
    }

    setPasswordInfo({
      ...passwordInfo,
      [id]: value,
    });
  };

  const changePasswordStrength = (pw) => {
    setPasswordEntered(true);
    // 1 lowercase, 1 uppercase, 1 number, 1 special character, length: 8
    var strongPW = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    // 1 lowercase, 1 uppercase, 1 number or 1 special character, length: 6
    var mediumPW1 = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
    var mediumPW2 = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{6,})"
    );

    if (strongPW.test(pw)) {
      setPasswordStrength({
        value: 100,
        text: "Strong",
        color: colors.PWStrong,
      });
    } else if (mediumPW1.test(pw) || mediumPW2.test(pw)) {
      setPasswordStrength({
        value: 66,
        text: "Medium",
        color: colors.PWMedium,
      });
    } else {
      setPasswordStrength({
        value: 33,
        text: "Weak",
        color: colors.PWWeak,
      });
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (passwordInfo.newPassword !== passwordInfo.confirmNewPassword) {
      setErrors({ ...errors, confirmNewPassword: "Passwords do not match" });
      return;
    }

    if (passwordStrength.text === "Weak") {
      setErrors({
        ...errors,
        newPassword: `Password is weak. Please enter a stronger password.
           You can include lower and uppercase letters, numbers and special characters.`,
      });
      return;
    }

    setIsLoading(true);
    setUpdateSuccess(null);
    setGenericError("");
    try {
      const response = await axios.patch(
        `${BACKEND_URL}/users/changePassword`,
        passwordInfo
      );
      if (response.data.success) {
        setUpdateSuccess(true);
        setPasswordInfo({
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        });
        setPasswordEntered(false);
        setIsLoading(false);
        // Otherwise if user reloads the page, withour logging out, he will still be logged in
        setAuthToken(null);
        localStorage.removeItem("urlAbuseToken");
        localStorage.removeItem("urlAbuseUser");
        setOpen(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleAuthFail(location.pathname);
        return;
      }

      if (error.response?.data.errors) {
        setErrors(error.response.data.errors);
      } else if (error.response?.data.error) {
        setGenericError(error.response.data.error);
      }
      setUpdateSuccess(false);
      setIsLoading(false);
    }
  };

  const displayMessage = () => {
    if (updateSuccess) {
      return (
        <div style={classes.msgContainer}>
          <Message success style={classes.message}>
            <div style={classes.msgTitle}>Password changed successfully!</div>

            <div style={classes.iconContainer}>
              <CustomCloseIcon
                name="close"
                onClick={() => {
                  setUpdateSuccess(false);
                }}
              />
            </div>
          </Message>
        </div>
      );
    } else if (updateSuccess === false && genericError?.length > 0) {
      return (
        <div style={classes.msgContainer}>
          <Message error style={classes.message}>
            <div style={classes.msgTitle}>{genericError}</div>

            <div style={classes.iconContainer}>
              <CustomCloseIcon
                name="close"
                onClick={() => {
                  setGenericError("");
                }}
              />
            </div>
          </Message>
        </div>
      );
    }
  };

  return (
    <Segment>
      <LogoutModal open={open} setOpen={setOpen} />
      <Header as="h5" style={classes.header}>
        Change Password
      </Header>
      <div>
        {displayMessage()}
        <Form style={classes.form} onSubmit={submitForm}>
          <Form.Group>
            <Form.Field
              id="currentPassword"
              control={Input}
              label="Current Password"
              placeholder="Current Password"
              value={passwordInfo.currentPassword}
              type="Password"
              width="16"
              onChange={handleChange}
              error={errors.currentPassword}
              required
              style={classes.currentPassword}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field
              id="newPassword"
              control={Input}
              label="New Password"
              placeholder="New Password"
              value={passwordInfo.newPassword}
              type="Password"
              width="16"
              onChange={handleChange}
              error={errors.newPassword}
              required
              style={classes.newPassword}
            />
          </Form.Group>

          {passwordEntered && (
            <div className="ui progress" style={{ width: "100%" }}>
              <div className="bar" style={classes.progressContaienr}>
                <div
                  className="progress progress-text"
                  style={classes.progressText}
                >
                  {passwordStrength.text}
                </div>
              </div>
            </div>
          )}

          <Form.Group>
            <Form.Field
              id="confirmNewPassword"
              control={Input}
              label="Confirm New Password"
              placeholder="Confirm New Password"
              value={passwordInfo.confirmNewPassword}
              type="Password"
              width="16"
              onChange={handleChange}
              error={errors.confirmNewPassword}
              required
            />
          </Form.Group>

          <Form.Group style={classes.btnContainer}>
            <Form.Button
              disabled={isLoading}
              loading={isLoading}
              color={"orange"}
              style={classes.updateBtn}
              type="submit"
            >
              Update
            </Form.Button>
          </Form.Group>
        </Form>
      </div>
    </Segment>
  );
}

export default ChangePassword;
