import React, { useState, useEffect } from "react";
import { Menu, Sidebar } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import "./Sidebar.css";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

function MainSidebar(props) {
  let history = useHistory();
  let location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const { isAuthenticated } = useSelector((state) => state.auth);

  const [state, setstate] = useState({ activeItem: "" });

  useEffect(() => {
    if (location.pathname === "/add-abuse")
      setstate({ activeItem: "add-abuse" });
    else if (location.pathname === "/login") setstate({ activeItem: "login" });
    else if (location.pathname === "/register")
      setstate({ activeItem: "register" });
    else setstate({ activeItem: "" });
  }, [location.pathname]);

  const goToAddAbuse = (e) => {
    history.push("/add-abuse");
  };

  const goToLogin = () => {
    history.push("/login");
  };

  const goToSignUp = () => {
    history.push("/register");
  };

  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      onHide={() => props.setVisible(false)}
      onShow={() => props.setVisible(true)}
      vertical
      visible={props.isVisible}
      style={{ width: "200px" }}
      inverted
    >
      <Menu.Item
        active={state.activeItem === "add-abuse"}
        as="a"
        onClick={goToAddAbuse}
      >
        Add Abuse
      </Menu.Item>
      {!isAuthenticated && isMobile && (
        <Menu.Item
          active={state.activeItem === "login"}
          as="a"
          onClick={goToLogin}
          name="Search Results"
        >
          Log in
        </Menu.Item>
      )}
      {!isAuthenticated && isMobile && (
        <Menu.Item
          active={state.activeItem === "register"}
          as="a"
          onClick={goToSignUp}
          name="Search Results"
        >
          Sign up
        </Menu.Item>
      )}
    </Sidebar>
  );
}

export default MainSidebar;
