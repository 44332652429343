import { colors } from "../../../Themes";

export const indexStyles = (isMobile) => ({
  wrapper: { width: "100%" },
  quotasTitle: { fontSize: "20px", color: colors.greyBlack },
  settingsTitle: {
    fontSize: "20px",
    color: colors.greyBlack,
    marginTop: "30px",
  },
  table: { width: isMobile ? "100%" : "70%" },
  label: { minWidth: "60px", textAlign: "center" },
  errorMsg: { textAlign: "center" },
  percentage: { marginLeft: "10px", color: colors.orange },
});
