import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Line } from "react-chartjs-2";
import axios from "axios";
import { BACKEND_URL } from "../../Config";
import { lineChartStyles } from "./styles";

const optionsForTabOrDesktop = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 2,
  plugins: {
    title: {
      display: true,
      text: (ctx) => "Reported abuse types for the past 6 months",
    },
    tooltip: {
      mode: "index",
    },
  },
  interaction: {
    mode: "nearest",
    axis: "x",
    intersect: false,
  },
  scales: {
    x: {
      grid: {
        display: true,
      },
    },
    y: {
      grid: {
        display: true,
      },
    },
  },
};

const optionsForPhone = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 1.2,
  plugins: {
    title: {
      display: true,
      text: (ctx) => "Reported abuse types for the past 6 months",
    },
    tooltip: {
      mode: "index",
    },
  },
  interaction: {
    mode: "nearest",
    axis: "x",
    intersect: false,
  },
  scales: {
    x: {
      grid: {
        display: true,
      },
    },
    y: {
      grid: {
        display: true,
      },
    },
  },
};

function LineAreaChart(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const classes = lineChartStyles();
  const [phishing, sePhishing] = useState([]);
  const [spam, setSpam] = useState([]);
  const [other, setOther] = useState([]);
  const [scans, setScans] = useState([]);
  const [months, setMonths] = useState([]);

  useEffect(() => {
    retrievePlotData();
  }, []);

  const retrievePlotData = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/plots/data`);

      if (response.data.success) {
        sePhishing(response.data.phishing);
        setSpam(response.data.spam);
        setOther(response.data.other);
        setScans(response.data.scans);
        setMonths(response.data.months);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const generateLineChart = (moreData = {}) => {
    return {
      labels: months,
      datasets: [
        {
          label: "Phishing Reports",
          data: phishing,
          borderColor: classes.colors.phishing,
          backgroundColor: classes.colors.phishing,
          borderWidth: 1,
          tension: 0.3,
          ...moreData,
        },
        {
          label: "Spam Reports",
          data: spam,
          borderColor: classes.colors.spam,
          backgroundColor: classes.colors.spam,
          borderWidth: 1,
          tension: 0.3,
          ...moreData,
        },
        {
          label: "Other Reports",
          data: other,
          borderColor: classes.colors.other,
          backgroundColor: classes.colors.other,
          borderWidth: 1,
          tension: 0.3,
          ...moreData,
        },
        {
          label: "Scans",
          data: scans,
          borderColor: classes.colors.scan,
          backgroundColor: classes.colors.scan,
          borderWidth: 1,
          tension: 0.3,
          ...moreData,
        },
      ],
    };
  };

  return (
    <div style={classes.container}>
      {isMobile ? (
        <Line
          data={generateLineChart({ fill: false })}
          options={optionsForPhone}
        />
      ) : (
        <Line
          data={generateLineChart({ fill: false })}
          options={optionsForTabOrDesktop}
        />
      )}
    </div>
  );
}

export default LineAreaChart;
