export const indexStyles = (isMobile) => ({
  container: {
    marginBottom: "10px",
  },
  lookupHeader: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    marginBottom: "10px",
  },
  form: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  urlInput: {
    width: isMobile ? "80%" : "50%",
    marginLeft: "-42px", // Half of the button width
  },
  errorTextContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "7px",
    color: "#de473c",
  },
  processingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "100px",
  },
  progressTextContainer: {
    width: "70%",
    fontSize: isMobile ? "16px" : "20px",
    marginBottom: "20px",
  },
  progressContainer: { width: "70%" },
  errorText: { fontSize: "20px" },
});
