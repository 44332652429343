import { colors } from "../../Themes";
import styled from "styled-components";
import { Button } from "semantic-ui-react";

export const AddReportButton = styled(Button)`
  color: #fff !important;
  background-color: ${colors.orange} !important;
  border: 1px solid ${colors.orange} !important;
  transition: 0.3s;
  &:hover {
    color: ${colors.orange} !important;
    background-color: transparent !important;
    transition: 0.3s;
  }
`;

export const ViewStatisticsButton = styled(Button)`
  color: ${colors.orange} !important;
  background-color: transparent !important;
  border: 1px solid ${colors.orange} !important;
  transition: 0.3s;
  &:hover {
    color: #fff !important;
    background-color: ${colors.orange} !important;
    transition: 0.3s;
  }
`;

export const indexStyles = (isMobile) => ({
  container: {
    backgroundColor: colors.grey,
    paddingBottom: isMobile ? "30px" : "50px",
  },
  gridColumn: { padding: "0px" },
  mainHeader: {
    fontSize: isMobile ? "25px" : "48px",
    marginTop: isMobile ? "30px" : "40px",
  },
  addAbuseButton: { marginRight: "5px", minWidth: "160px" },
  statButton: { marginLeft: "5px", minWidth: "160px" },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "10px",
  },
  chartContainer: {
    width: "90%",
    display: "flex",
    justifyContent: "center",
  },
  
});
