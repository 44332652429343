import React from "react";
import ChangeInfo from "./ChangeInfo";
import ChangePassword from "./ChangePassword";

function ProfileInfo() {
  return (
    <div>
      <ChangeInfo />
      <ChangePassword />
    </div>
  );
}

export default ProfileInfo;
