import { colors } from "../../Themes";

export const indexStyles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "150px",
  },
  textContainer: {
    marginTop: "20px",
    marginBottom: "20px",
    fontSize: "50px",
    color: colors.greyBlack,
  },
  homeBtn: { marginTop: "25px" },
});
