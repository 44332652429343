import React, { useState, useEffect } from "react";
import { Grid, Image, Divider, Segment } from "semantic-ui-react";
import { Icon, Tab } from "semantic-ui-react";
import { capitalizeFirstLetter } from "../../../utils/helpers";
import { colors } from "../../../Themes";
import { useMediaQuery } from "react-responsive";
import { tabScreenshotStyles } from "./styles";

function HeadingAndScreenshot(props) {
  const isTablet = useMediaQuery({ query: "(max-width: 750px)" });
  const classes = tabScreenshotStyles(isTablet);

  const { report, image, returnAbuseType, returnIP, type } = props;

  const returnComingSoon = () => {
    return (
      <Segment
        style={{
          padding: "20px",
          minHeight: isTablet ? "20px" : "341px",
        }}
      >
        <div
          style={{
            minHeight: isTablet ? "20px" : "341px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: "20px", color: colors.greyBlack }}>
            Coming Soon...
          </p>
        </div>
      </Segment>
    );
  };

  /**
   * report types:
   *  1. abuseReport
   *  2. activeScan
   */
  const [panes, setPanes] = useState([
    {
      menuItem: "Summary",
      render: () => (
        <div>
          <Grid>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div style={classes.title}>
                {type === "abuseReport" ? "Abuse Message" : "Scan Summary"}
              </div>
              <Segment style={classes.segment}>
                {type === "abuseReport" && (
                  <div>
                    <div style={classes.descriptionTitle}>Description</div>
                    <Divider style={classes.divider} />
                    <div style={classes.description}>{report.description}</div>
                  </div>
                )}

                {/* <div style={classes.urlContainer}>
                  <div>
                    <a href={report.url} target="_blank" style={classes.url}>
                      {truncateString(report.url, 30)}
                    </a>
                    <span style={{ margin: "0px 5px" }}> reported </span>
                    <span style={{ fontWeight: "600", color: colors.orange }}>
                      5 times
                    </span>
                    <span style={{ margin: "0px 5px" }}>in urlabuse</span>
                  </div>
                  
                  <div style={classes.summaryBtnContainer}>
                    <Button
                      color="orange"
                      size="tiny"
                      style={classes.summaryBtn}
                    >
                      Summary
                    </Button>
                  </div>
                </div>
                <Divider /> */}

                <div style={classes.liveInfoContainer}>
                  <div style={classes.liveInfo}>Live Information</div>
                  <Divider style={classes.divider} />

                  {type === "abuseReport" && (
                    <div style={classes.infoItemContainer}>
                      <span style={classes.infoTitle}>URL abuse type - </span>
                      {capitalizeFirstLetter(returnAbuseType())} Attack
                    </div>
                  )}

                  <div style={classes.infoItemContainer}>
                    <span style={classes.infoTitle}>
                      {" "}
                      Google Safe Browsing{" "}
                    </span>
                    <Icon
                      color="teal"
                      name="check circle"
                      style={classes.checkIcon}
                    />
                    <span style={classes.classification}>
                      No classification
                    </span>
                  </div>

                  <div style={classes.infoDns}>
                    {returnIP() ? (
                      <span style={classes.infoTitle}>
                        Current DNS A record: {returnIP()}
                      </span>
                    ) : (
                      <>
                        <span style={classes.infoTitle}>
                          Current DNS A record:
                        </span>
                        <Icon
                          color="teal"
                          name="check circle"
                          style={classes.checkIcon}
                        />
                        <span style={classes.classification}>
                          No classification
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </Segment>
            </Grid.Column>

            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div style={classes.screenshotTitle}>Screenshot</div>

              {image ? (
                <Image
                  src={`data:image/png;base64,${image}`}
                  fluid
                  style={classes.screenshot}
                  bordered
                />
              ) : (
                <div>
                  <Segment style={classes.noScreenshotSegment}>
                    <div style={classes.textContainer}>
                      <Icon
                        color="orange"
                        name="exclamation"
                        style={classes.errorIcon}
                      />
                      We could not find a screenshot for the given URL.
                    </div>
                  </Segment>
                </div>
              )}
            </Grid.Column>
          </Grid>
        </div>
      ),
    },
    {
      menuItem: "Similar",
      render: () => {
        return <div>{returnComingSoon()}</div>;
      },
    },
    {
      menuItem: "DCIM",
      render: () => {
        return <div>{returnComingSoon()}</div>;
      },
    },
    {
      menuItem: "Content",
      render: () => {
        return <div>{returnComingSoon()}</div>;
      },
    },
  ]);

  useEffect(() => {
    if (!isTablet) {
      setPanes([
        ...panes,
        {
          menuItem: "Screenshot Similar",
          render: () => {
            return <div>{returnComingSoon()}</div>;
          },
        },
        {
          menuItem: "Screenshot Summary",
          render: () => {
            return <div>{returnComingSoon()}</div>;
          },
        },
      ]);
    } else {
      const newArr = panes?.slice(0, 4);
      setPanes(newArr);
    }
  }, [isTablet]);

  return (
    <>
      <Tab
        menu={{ color: "orange", secondary: true, pointing: true }}
        panes={panes}
      />
    </>
  );
}

export default HeadingAndScreenshot;
