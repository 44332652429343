import { colors } from "../../../Themes";
import styled from "styled-components";
import { Menu, Button } from "semantic-ui-react";

export const SignInButton = styled(Button)`
  color: ${colors.orange} !important;
  background-color: transparent !important;
  border: 1px solid ${colors.orange} !important;
  transition: 0.3s;
  &:hover {
    color: #fff !important;
    background-color: ${colors.orange} !important;
    transition: 0.3s;
  }
`;

export const SignUpButton = styled(Button)`
  color: #fff !important;
  background-color: ${colors.orange} !important;
  border: 1px solid ${colors.orange} !important;
  transition: 0.3s;
  &:hover {
    color: ${colors.orange} !important;
    background-color: transparent !important;
    transition: 0.3s;
  }
`;

export const AbuseMenuItem = styled(Menu.Item)`
  color: ${colors.orange} !important;
  background-color: transparent !important;
  border: 1px solid ${colors.orange} !important;
  transition: 10.3s;
  &:hover {
    color: #fff !important;
    background-color: ${colors.orange} !important;
    transition: 0.3s;
  }
`;

/**
 * Searchbar Components
 */

export const Container = styled.div`
  font-family: "Arial";
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  background: #f1f3f4;
  min-height: 25px;
`;

export const TextInput = styled.input`
  color: ${colors.darkGrey};
  flex: 1 0;
  width: 100%;
  min-height: 25px;
  font-size: inherit;
  background-color: transparent;
  padding-left: 5px;
  border: 0px;
  &:focus {
    outline: none;
  }
`;

export const responsiveBigStyles = () => ({
  grid: {
    backgroundColor: colors.white,
    paddingTop: "7px",
  },
  menu: {
    marginLeft: "-13px",
    marginRight: "-13px",
  },
  menuItem: {
    width: "45%",
  },
  searchBarContainer: {
    marginLeft: "90px",
    width: "100%",
  },
  navbarImageContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  navbarImg: {
    height: "22px",
  },
  dropdown: {
    display: "flex",
    justifyContent: "center",
  },
  dropdownMenu: { width: "250px" },
  dropdownItemProfile: { height: "60px", display: "flex" },
  profileText: { marginBottom: "8px", fontSize: "15px" },
  emailText: { fontSize: "13px", color: colors.greyBlack },
  dropdownItemLogout: {
    height: "60px",
    display: "flex",
    fontSize: "17px",
  },
  menuItemSignup: { paddingLeft: "0px" },
  divider: {
    width: "100%",
    marginTop: "7px",
    backgroundColor: colors.grey,
  },
});

export const responsiveSmallStyles = () => ({
  menu: {
    backgroundColor: colors.white,
    paddingTop: "14px",
    paddingBottom: "14px",
    marginBottom: "0px",
  },
  menuItemSearchVisible: { width: "100%" },
  searchBarContainer: { width: "100%" },
  closeIcon: { cursor: "pointer", marginLeft: "10px" },
  menuItemSideBarIcon: { marginLeft: "10px" },
  sidebarCloseIcon: { cursor: "pointer" },
  sidebarMenuIcon: { cursor: "pointer" },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  logo: { height: "22px" },
  menuItemSearchBar: { width: "40%" },
  searchBarContainer: {
    marginLeft: "20px",
    width: "100%",
  },
  menuItemSearch: {
    display: "flex",
    alignItems: "center",
    paddingRight: "0px",
  },
  searchIcon: { color: colors.greyBlack },
  dropdown: {
    display: "flex",
    justifyContent: "center",
  },
  dropdownMenu: { width: "250px" },
  dropdownItemProfile: { height: "60px", display: "flex" },
  profileText: { marginBottom: "8px", fontSize: "15px" },
  emailText: {
    fontSize: "13px",
    color: colors.greyBlack,
  },
  dropdownItemLogout: {
    height: "60px",
    display: "flex",
    fontSize: "17px",
  },
  menuItemLogin: { paddingLeft: "0px", paddingRight: "0px" },
  menuItemSignup: { paddingLeft: "0px" },
  notAuthMenuItemSearch: {
    display: "flex",
    alignItems: "center",
  },
  divider: { marginTop: "0px" },
});

export const searchBarStyles = () => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  container: { width: "100%" },
  searchIcon: {
    color: "#a6a6a6",
    marginLeft: "5px",
    height: "100%",
  },
});
