import { colors } from "../../Themes";

export const indexStyles = (isMobile) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "120px",
    paddingLeft: isMobile ? "10px" : "",
    paddingRight: isMobile ? "10px" : "",
  },
  message: {
    marginTop: "-60px",
    width: "400px",
    textAlign: "center",
  },
  card: { width: "400px" },
  form: { margin: "20px" },
  emailField: { marginBottom: "10px" },
  verifyText: {
    color: "#B03060",
  },
  recaptchaContainer: {
    paddingLeft: "7px",
    marginTop: "20px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "40px",
  },
  forgotPassword: {
    textAlign: "center",
    marginTop: isMobile ? "10px" : "",
  },
});

export const forgotPassword = (isMobile) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "120px",
    paddingLeft: isMobile ? "10px" : "",
    paddingRight: isMobile ? "10px" : "",
  },
  card: { width: isMobile ? "100%" : "500px" },
  form: { margin: "20px" },
  textContainer: { marginBottom: "20px" },
  firstText: { marginBottom: "5px" },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "40px",
  },
  message: {
    marginTop: "-60px",
    width: isMobile ? "100%" : "500px",
    textAlign: "center",
    paddingLeft: isMobile ? "10px" : "",
    paddingRight: isMobile ? "10px" : "",
  },
});

export const resetPassword = (isMobile) => ({
  wrapper: {
    marginTop: "150px",
    paddingLeft: isMobile ? "10px" : "",
    paddingRight: isMobile ? "10px" : "",
  },
  loadingContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  errorContainerOuter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: isMobile ? "0px 20px" : "0px",
  },
  errorContainerInner: {
    display: "flex",
    justifyContent: "center",
    flexDirection: isMobile ? "column" : "",
  },
  errorTextContainer: {
    height: "100%",
    fontSize: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    color: colors.greyBlack,
  },
  errorTitle: {
    marginBottom: "0px",
  },
  errorMsg: { fontSize: "18px" },
});

export const resetPasswordForm = (isMobile, passwordStrength) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  card: { width: isMobile ? "100%" : "500px" },
  form: { margin: "20px" },
  header: { marginBottom: "30px" },
  newPassword: { marginBottom: isMobile ? "15px" : "" },
  progressContainer: { width: "100%" },
  textContainerOuter: {
    width: `${passwordStrength.value}%`,
    height: "20px",
    backgroundColor: `${passwordStrength.color}`,
  },
  textContainerInner: { fontSize: "13px" },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "40px",
  },
  message: {
    marginTop: "-60px",
    width: "500px",
    textAlign: "center",
  },
});
