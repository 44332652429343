import { colors } from "../../../Themes";

export const indexStyles = (isMobile) => ({
  gridRow: isMobile ? { paddingBottom: "0px" } : {},
  breadCrumb: {
    paddingLeft: isMobile ? "30px" : "",
    paddingRight: isMobile ? "30px" : "",
  },
  breadcrumbDivider: { color: "#777" },
  breadcrumbSection: { color: colors.orange },
  homeLink: { color: "#777" },
  errorText: {
    fontSize: "20px",
    fontWeight: "700",
    marginBottom: "5px",
    color: colors.greyBlack,
  },
  noResultsText: {
    fontSize: "20px",
    fontWeight: "700",
    marginBottom: "5px",
    color: colors.greyBlack,
  },
  noResultsSubText: {
    fontSize: "16px",
    color: colors.greyBlack,
  },
  resultContainer: {
    marginTop: isMobile ? "15px" : "1px",
    paddingLeft: isMobile ? "30px" : "",
    paddingRight: isMobile ? "30px" : "",
  },
  cardContainer: { cursor: "pointer" },
  card: { marginBottom: "10px" },
  cardContent: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  reportCountCardContent: { color: "#666" },
  domain: {
    fontSize: "15px",
    fontWeight: 600,
    marginBottom: "0px",
    color: colors.darkGrey,
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    marginBottom: "30px",
  },
});

export const filtersStyles = (isMobile) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: isMobile ? "center" : "flex-end",
    paddingLeft: isMobile ? "30px" : "",
    paddingRight: isMobile ? "30px" : "",
  },
  segment: {
    width: isMobile ? "100%" : "70%",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
});
