import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

function ReCaptcha({ recaptchaRef }) {
  return (
    <>
      {process.env.REACT_APP_SITE_KEY && (
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_SITE_KEY}
        />
      )}
    </>
  );
}

export default ReCaptcha;
