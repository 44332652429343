import React from "react";
import { Segment } from "semantic-ui-react";
import { colors } from "../../../Themes";
import { statisticStyles } from "./styles";

function Statistics() {
  const classes = statisticStyles();
  return (
    <div style={classes.container}>
      <div style={classes.statTitle}>Statistics</div>

      <div style={{ height: "90%" }}>
        <Segment style={{ height: "100%" }}>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "20px", color: colors.greyBlack }}>
              Coming Soon...
            </p>
          </div>
        </Segment>
      </div>
    </div>
  );
}

export default Statistics;
