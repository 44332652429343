import React from "react";
import { Header, Divider } from "semantic-ui-react";
import { privacyStyles } from "./styles";

function PrivacyPolicy() {
  const classes = privacyStyles();

  return (
    <div style={classes.privacyContainerOuter}>
      <div style={classes.privacyContainerInner}>
        <div>
          <Header as="h2"> Privacy Policy </Header>
          <div>
            Your privacy is important to us. It is URL Abuse's policy to respect
            your privacy regarding any information we may collect from you
            across our website, https://urlabuse.webtelescope.io/, and other
            sites we own and operate.
          </div>
        </div>

        <div style={classes.textContainer}>
          <Header as="h3" style={classes.h3Header}>
            Information we collect
          </Header>
          <Divider style={classes.divider} />

          <Header as="h4" style={classes.h4Header}>
            Personal information
          </Header>
          <div>
            We may ask for personal information, such as your name and email
            address. This data is considered “identifying information”, as it
            can personally identify you. We only request personal information
            relevant to providing you with a service, and only use it to help
            provide or improve this service.
          </div>

          <Header as="h4" style={classes.h4Header}>
            How we collect information
          </Header>
          <div>
            We collect information by fair and lawful means, with your knowledge
            and consent. We also let you know why we’re collecting it and how it
            will be used. You are free to refuse our request for this
            information, with the understanding that we may be unable to provide
            you with some of your desired services without it.
          </div>

          <Header as="h3" style={classes.h3Header}>
            Children’s Privacy
          </Header>
          <Divider style={classes.divider} />

          <div>
            This website does not knowingly target or collect personal
            information from children. As a parent/guardian, please contact us
            if you believe your child is participating in an activity involving
            personal information on our website, and you have no received a
            notification or request for consent. We do not use your supplied
            contact details for marketing or promotional purposes.
          </div>

          <Header as="h3" style={classes.h3Header}>
            Limits of our policy
          </Header>
          <Divider style={classes.divider} />

          <div>
            This privacy policy only covers URL Abuse's own collecting and
            handling of data. We only work with partners, affiliates and
            third-party providers whose privacy policies align with ours,
            however we cannot accept responsibility or liability for their
            respective privacy practices. <br />
            <br /> Our website may link to external sites that are not operated
            by us. Please be aware that we have no control over the content and
            policies of those sites, and cannot accept responsibility or
            liability for their respective privacy practices.
          </div>

          <Header as="h3" style={classes.h3Header}>
            Changes to this policy
          </Header>
          <Divider style={classes.divider} />

          <div>
            At our discretion, we may update this policy to reflect current
            acceptable practices. We will take reasonable steps to let users
            know about changes via our website. Your continued use of this site
            after any changes to this policy will be regarded as acceptance of
            our practices around data and personal information.
          </div>

          <Header as="h3" style={classes.h3Header}>
            Your rights and responsibilities
          </Header>
          <div>
            As our user, you have the right to be informed about how your data
            is collected and used. You are entitled to know what data we collect
            about you, and how it is processed. <br />
            <br />
            You are entitled to correct and update any personal information
            about you, and to request this information be deleted. You are
            entitled to restrict or object to our use of your data, while
            retaining the right to use your personal information for your own
            purposes. You have the right to opt out of data about you being used
            in decisions based solely on automated processing. <br /> <br />
            Feel free to contact us if you have any concerns or questions about
            how we handle your data and personal information. <br /> <br /> This
            policy is effective as of 1 December 2021.
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
