import React, { useState, useEffect, useRef } from "react";
import { Container, Card, Form, Input, Button } from "semantic-ui-react";
import { Select, TextArea, Message, Progress } from "semantic-ui-react";
import axios from "axios";
import { BACKEND_URL } from "../../Config";
import { useHistory, useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import { useMediaQuery } from "react-responsive";
import useHandleAuthFail from "../../utils/useHandleAuthFail";
import { indexStyles } from "./styles";
import ReCaptcha from "../Helpers/ReCaptcha";

const abuseTypes = [
  { key: "p", text: "Phishing", value: "phishing" },
  { key: "s", text: "Spam", value: "spam" },
  { key: "o", text: "Other", value: "other" },
];

// const abuseTypes = []
const getInTypes = [
  { key: "t1", text: "Option1", value: "option1" },
  { key: "t2", text: "Option2", value: "option2" },
  { key: "t3", text: "Other", value: "other" },
];

function Addabuse() {
  const isMobile = useMediaQuery({ query: "(max-width: 750px)" });
  const classes = indexStyles(isMobile);
  const location = useLocation();
  const history = useHistory();
  const handleAuthFail = useHandleAuthFail();

  const recaptchaRef = useRef();
  const [state, setState] = useState({
    url: "",
    abuseType: abuseTypes[0].value,
    other: "",
    getInType: getInTypes[0].value,
    getInSource: "",
    description: "",
    privacyCheck: false,
  });

  const [isAbuseTypeOther, setIsAbuseTypeOther] = useState(false);
  const [isVerificationFailed, setIsVerificationFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    url: null,
    other: null,
    getInSource: null,
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [newReportId, setNewReportId] = useState(null);
  const [genericError, setGenericError] = useState("");

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const id = target.id;

    if (errors[id] !== null) {
      setErrors({
        ...errors,
        [id]: null,
      });
    }

    setState({
      ...state,
      [id]: value,
    });
  };

  const handleAbuseType = (e, { value }) => {
    if (value === "other") {
      setIsAbuseTypeOther(true);
    } else {
      setIsAbuseTypeOther(false);
    }
    setState({
      ...state,
      abuseType: value,
    });
  };

  const handleGetInType = (e, { value }) => {
    setState({
      ...state,
      getInType: value,
    });
  };

  const privacyCheck = (e, data) => {
    setState({
      ...state,
      privacyCheck: data.checked,
    });
  };

  const displayErrorMessage = () => {
    if (genericError?.length > 0) {
      return <Message error content={genericError} />;
    }
  };

  useEffect(() => {
    updateProgress();
  }, [newReportId]);

  useEffect(() => {
    return () => {
      setIsProcessing(false);
      setProgress(0);
      setNewReportId(null);
    };
  }, []);

  const updateProgress = () => {
    if (newReportId) {
      const socket = io(BACKEND_URL);
      socket.emit("join_socket_room");

      socket.on("connect", () => {
        socket.emit("new_report_in_progress", newReportId);

        socket.on("report_progress", (state) => {
          setProgress(Math.ceil(state * 20));
        });

        socket.on("report_process_finished", (report) => {
          socket.emit("update_reports_table");
          socket.disconnect();
          history.push(
            `/search-results?type=${"abuseReport"}&reportId=${report._id}`
          );
        });

        socket.on("process_error", () => {
          setIsProcessing(false);
          setProgress(0);
          setGenericError("Something went wrong. Please try again later.");
          setIsLoading(false);
        });
      });
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const recaptchaToken = recaptchaRef.current.getValue();

    // Check recaptcha verification
    if (recaptchaToken?.length === 0) {
      setIsVerificationFailed(true);
      return;
    }

    recaptchaRef.current.reset();
    setIsLoading(true);
    setGenericError("");
    setIsVerificationFailed(false);
    try {
      const response = await axios.post(`${BACKEND_URL}/reports/add`, {
        ...state,
        recaptchaToken,
      });

      if (response.data.success) {
        setIsLoading(false);
        setIsProcessing(true);
        setNewReportId(response.data.reportId);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 401) {
        handleAuthFail(location.pathname);
        return;
      }
      if (error.response?.data.errors) {
        setErrors(error.response.data.errors);
      } else if (error.response?.data.error) {
        setGenericError(error.response.data.error);
      }
    }
  };

  const displayForm = () => {
    if (isProcessing) {
      return (
        <Container style={classes.formConainer}>
          <div style={classes.progressTextContainer}>
            <p>We are processing your report. Please wait . . . </p>
          </div>
          <div style={classes.progress}>
            <Progress percent={progress} color="orange" />
          </div>
        </Container>
      );
    }

    return (
      <>
        {displayErrorMessage()}
        <Card fluid style={classes.card}>
          <Container>
            <Form style={classes.form} onSubmit={submitForm}>
              <Form.Group>
                <Form.Field
                  id="url"
                  control={Input}
                  label="URL"
                  placeholder="URL"
                  value={state.url}
                  width="16"
                  onChange={handleChange}
                  error={errors.url}
                  required
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  control={Select}
                  options={abuseTypes}
                  value={state.abuseType}
                  label={{ children: "Abuse Type" }}
                  onChange={handleAbuseType}
                  searchInput={{ id: "abuse_type" }}
                />
                {isAbuseTypeOther && (
                  <Form.Field
                    id="other"
                    control={Input}
                    label="If other, please specify"
                    onChange={handleChange}
                    value={state.other}
                    error={errors.other}
                    required
                  />
                )}
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  control={Select}
                  options={getInTypes}
                  value={state.getInType}
                  label={{ children: "How did you get in" }}
                  onChange={handleGetInType}
                  // search
                  searchInput={{ id: "get_in" }}
                />
                <Form.Field
                  id="getInSource"
                  control={Input}
                  value={state.getInSource}
                  label="Mention the source"
                  onChange={handleChange}
                  error={errors.getInSource}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  id="description"
                  control={TextArea}
                  label="Description"
                  placeholder="Provide a description of the abuse"
                  value={state.description}
                  width="16"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Checkbox
                  label="Share my contact information with applicable law enforcement"
                  style={classes.checkbox}
                  onClick={(e, data) => privacyCheck(e, data)}
                  checked={state.privacyCheck}
                />
              </Form.Group>

              {isVerificationFailed && (
                <div style={classes.verifyText}>Please Verify Yourself</div>
              )}
              <Form.Group style={classes.recaptchaContainer}>
                <ReCaptcha recaptchaRef={recaptchaRef} />
              </Form.Group>

              <div style={classes.addReportContainer}>
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  color={"yellow"}
                >
                  Add Report
                </Button>
              </div>
            </Form>
          </Container>
        </Card>
      </>
    );
  };

  return (
    <>
      <Container style={classes.container}>{displayForm()}</Container>
    </>
  );
}

export default Addabuse;
