import React, { useState, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import { Label } from "semantic-ui-react";
import DetailTable from "./DetailTable";
import moment from "moment";
import axios from "axios";
import { BACKEND_URL } from "../../../Config";
import Lottie from "react-lottie";
import Loading from "../../Lotties/loading.json";
import TabAndScreenshot from "./TabAndScreenshot";
import { capitalizeFirstLetter, truncateString } from "../../../utils/helpers";
import Statistics from "./Statistics";
import Blag from "./Blag";
import useQuery from "../../../utils/useQuery";
import { Icon } from "semantic-ui-react";
import { indexStyles } from "./styles";

function SearchResults() {
  const classes = indexStyles();
  const query = useQuery();
  const reportId = query.get("reportId");
  const type = query.get("type");

  const [isLoading, setIsLoading] = useState(true);
  const [report, setReport] = useState(null);
  const [image, setImage] = useState(null);
  const [genericError, setGenericError] = useState("");

  useEffect(() => {
    retrieveReport();
  }, []);

  const retrieveReport = async () => {
    if (!type || !reportId) {
      setIsLoading(false);
      return;
    }
    setGenericError("");
    try {
      var response;
      if (type === "abuseReport") {
        response = await axios.get(`${BACKEND_URL}/search/reportDetails/${reportId}`);
      } else if (type === "activeScan") {
        response = await axios.get(`${BACKEND_URL}/search/scanDetails/${reportId}`);
      }
      if (response.data?.success) {
        setReport(response.data?.report);
        setImage(response.data?.screenshot);
      }
    } catch (error) {
      if (error.response?.data.error) {
        setGenericError(error.response.data.error);
      }
    }
    setIsLoading(false);
  };

  const returnAbuseType = () => {
    if (report.abuseTypeInfo?.abuseType === "other") {
      return report.abuseTypeInfo?.abuseTypeSpecify;
    }
    return report.abuseTypeInfo?.abuseType;
  };

  const returnDate = (date) => {
    const d = new Date(date);
    var dateStringWithTime = moment(d)?.format("dddd, MMMM DD, YYYY, hh:mm A");
    return dateStringWithTime;
  };

  const returnIP = () => {
    if (report?.A?.length > 0) {
      return report?.A[0]?.answer;
    }
  };

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const displayReport = () => {
    if (isLoading) {
      return (
        <div style={classes.loadingContainer}>
          <Lottie options={loadingOptions} height={150} width={150} />
        </div>
      );
    } else if (!report && genericError?.length > 0) {
      return (
        <Grid>
          <Grid.Column mobile={1} tablet={1} computer={1} />
          <Grid.Column mobile={14} tablet={14} computer={14}>
            <div>
              <p style={classes.notAvailableText}>
                <Icon name="exclamation" style={classes.notAvailableIcon} />
                {genericError}
              </p>
            </div>
          </Grid.Column>
          <Grid.Column mobile={1} tablet={1} computer={1} />
        </Grid>
      );
    } else if (report) {
      return (
        <>
          <Grid style={classes.reportGrid}>
            <Grid.Column mobile={1} tablet={1} computer={1}></Grid.Column>
            <Grid.Column mobile={14} tablet={14} computer={14}>
              <Grid.Row style={classes.typeRow}>
                <div style={classes.typeContainer}>
                  {new URL(report.url).hostname.replace("www.", "")}
                  {type === "abuseReport" && (
                    <Label size="tiny" color="brown" style={classes.typeLabel}>
                      Abuse Report
                    </Label>
                  )}
                  {type === "activeScan" && (
                    <Label size="tiny" color="brown" style={classes.typeLabel}>
                      Active Scan
                    </Label>
                  )}
                </div>
              </Grid.Row>

              <Grid.Row style={classes.ipRow}>
                <div style={classes.ipText}>{returnIP()}</div>
              </Grid.Row>

              {type === "abuseReport" && (
                <Grid.Row style={classes.abuseTypeRow}>
                  <div style={classes.abuseTypeText}>
                    {capitalizeFirstLetter(returnAbuseType())} Attack
                  </div>
                </Grid.Row>
              )}

              <Grid.Row>
                <div>
                  <span style={classes.urlText}>URL: &nbsp;</span>
                  <a href={report.url} target="_blank" style={classes.url}>
                    {truncateString(report.url, 65)}
                  </a>
                </div>
              </Grid.Row>
              <Grid.Row>
                <div>
                  <span style={classes.submission}>Submission: &nbsp;</span>
                  <span style={classes.submissionDate}>
                    {returnDate(report.date)}
                  </span>
                </div>
              </Grid.Row>
              {/* End of middle column */}
            </Grid.Column>
            <Grid.Column mobile={1} tablet={1} computer={1}></Grid.Column>
          </Grid>

          <Grid style={classes.screenshotGrid}>
            <Grid.Column mobile={1} tablet={1} computer={1} />
            <Grid.Column mobile={14} tablet={14} computer={14}>
              <TabAndScreenshot
                report={report}
                image={image}
                returnAbuseType={returnAbuseType}
                returnIP={returnIP}
                type={type}
              />
            </Grid.Column>
            <Grid.Column mobile={1} tablet={1} computer={1} />
          </Grid>

          <Grid style={classes.tableGrid}>
            <Grid.Column mobile={1} tablet={1} computer={1} />
            <Grid.Column mobile={14} tablet={14} computer={14}>
              <div>
                <Grid>
                  <Grid.Column mobile={16} tablet={8} computer={8}>
                    <DetailTable report={report} />
                  </Grid.Column>

                  <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Statistics />
                  </Grid.Column>
                </Grid>
              </div>
            </Grid.Column>
            <Grid.Column mobile={1} tablet={1} computer={1} />
          </Grid>

          <Grid style={classes.blagGrid}>
            <Grid.Column mobile={1} tablet={1} computer={1} />
            <Grid.Column mobile={14} tablet={14} computer={14}>
              <Blag />
            </Grid.Column>
            <Grid.Column mobile={1} tablet={1} computer={1} />
          </Grid>
        </>
      );
    }
  };

  return <>{displayReport()}</>;
}

export default SearchResults;
