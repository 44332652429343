const colors = {
  grey: "#fcfcfc",
  orange: "#E97524",
  bgGrey: "#F5F5F5",
  PWStrong: "#ff8000",
  PWMedium: "#ee7700",
  PWWeak: "#cc6600",
  greyBlack: "#424547",
  darkGrey: "#6c757d",
  lightOrange: "#18bc9c",
  white: "#ffffff",
  linkBlue: "#4180e8"
};

module.exports = {
  colors,
};
