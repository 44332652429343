import React, { useState } from "react";
import { Card, Container, Form } from "semantic-ui-react";
import { Header, Input, Message } from "semantic-ui-react";
import axios from "axios";
import { BACKEND_URL } from "../../Config";
import { useMediaQuery } from "react-responsive";
import { forgotPassword } from "./styles";

function ForgotPassword() {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const classes = forgotPassword(isMobile);

  const [state, setState] = useState({
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const [genericError, setGenericError] = useState("");
  const [errors, setErrors] = useState({
    email: null,
  });

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const id = target.id;

    if (errors[id] !== null) {
      setErrors({
        ...errors,
        [id]: null,
      });
    }

    setState({
      ...state,
      [id]: value,
    });
  };

  const displayMessage = () => {
    if (isSuccess) {
      return (
        <Message
          style={classes.message}
          success
          content="If an account with the given email address exists, 
          we will send a password reset mail to that email. 
          Please check your inbox and follow the instructions to reset your password."
        />
      );
    } else if (isSuccess === false && genericError.length > 0) {
      return <Message style={classes.message} error content={genericError} />;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (state.email.trim() === "") {
      setErrors({
        ...errors,
        email: "Email cannot be empty.",
      });
      return;
    }

    setIsLoading(true);
    setGenericError("");
    setIsSuccess(null);

    try {
      const response = await axios.post(
        `${BACKEND_URL}/users/forgotPassword`,
        state
      );

      if (response.data.success) {
        setIsSuccess(true);
        setState({
          email: "",
        });
      }
    } catch (error) {
      setIsSuccess(false);
      if (error.response?.data.error) {
        setGenericError(error.response.data.error);
      } else if (error.response?.data.errors) {
        setErrors(error.response.data.errors);
      }
    }

    setIsLoading(false);
  };

  return (
    <div style={classes.wrapper}>
      {displayMessage()}
      <Card style={classes.card}>
        <Container>
          <Form style={classes.form} onSubmit={handleSubmit}>
            <Header as="h3" textAlign="center">
              Forgot Your Passwords?
            </Header>

            <div style={classes.textContainer}>
              <p style={classes.firstText}>
                Please enter the email address you used to sign up.
              </p>
              <p>
                We will send instructions on how to reset your password to this
                email address.
              </p>
            </div>

            <Form.Group>
              <Form.Field
                id="email"
                control={Input}
                placeholder="Email"
                width="16"
                onChange={handleChange}
                error={errors.email}
                value={state.email}
                required
              />
            </Form.Group>

            <Form.Group style={classes.btnContainer}>
              <Form.Button
                disabled={isLoading}
                loading={isLoading}
                color={"orange"}
              >
                Send Password Reset Email
              </Form.Button>
            </Form.Group>
          </Form>
        </Container>
      </Card>
    </div>
  );
}

export default ForgotPassword;
