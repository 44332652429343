import React, { useState, useEffect } from "react";
import { Menu, Divider, Dropdown, Grid } from "semantic-ui-react";
import { Image } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { logOutUser } from "../../../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "./SearchBar";
import Avatar from "react-avatar";
import Logo from "../../../images/URLAbuseLogo.svg";
import { responsiveBigStyles } from "./styles";
import { SignInButton, SignUpButton, AbuseMenuItem } from "./styles";

function ResponsiveBig() {
  const classes = responsiveBigStyles();
  let history = useHistory();
  let location = useLocation();
  let dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => state.auth);
  const { userData } = useSelector((state) => state.auth);
  const { userAuthData } = useSelector((state) => state.auth);

  const [state, setstate] = useState({ activeItem: "" });

  useEffect(() => {
    if (location.pathname === "/add-abuse")
      setstate({ activeItem: "add-abuse" });
  }, []);

  const goToHome = (e) => {
    setstate({ activeItem: "home" });
    if (location.pathname === "/") {
      window.location.reload(true);
      return;
    }
    history.push("/");
  };

  const goToAddAbuse = () => {
    setstate({ activeItem: "add-abuse" });
    history.push("/add-abuse");
  };

  const goToProfile = () => {
    history.push("/info/summary");
  };

  const goToLogin = () => {
    history.push("/login");
  };

  const goToSignUp = () => {
    history.push("/register");
  };

  const logOut = () => {
    logOutUser()(dispatch);
  };

  const trigger = (
    <div>
      <Avatar
        color="#f28057"
        name={`${userData.firstName}`}
        size={"30px"}
        round
      />
    </div>
  );

  const profileMenuItem = () => {
    return (
      <div>
        <div style={classes.profileText}>Profile</div>
        <div style={classes.emailText}>{userAuthData.email}</div>
      </div>
    );
  };

  return (
    <>
      <Grid style={classes.grid}>
        <Grid.Column mobile={16} tablet={2} computer={1} />
        <Grid.Column
          mobile={16}
          tablet={12}
          computer={14}
          verticalAlign={"bottom"}
        >
          <Menu secondary style={classes.menu}>
            <div onClick={goToHome} style={classes.navbarImageContainer}>
              <Image src={Logo} style={classes.navbarImg} />
            </div>
            <Menu.Item style={classes.menuItem}>
              <div style={classes.searchBarContainer}>
                <SearchBar></SearchBar>
              </div>
            </Menu.Item>

            <Menu.Item position="right"></Menu.Item>

            {isAuthenticated ? (
              <>
                <AbuseMenuItem
                  name="Add Abuse"
                  onClick={goToAddAbuse}
                  active={state.activeItem === "add-abuse"}
                />

                <Dropdown item style={classes.dropdown} trigger={trigger}>
                  <Dropdown.Menu style={classes.dropdownMenu} direction="left">
                    <Dropdown.Item
                      onClick={goToProfile}
                      style={classes.dropdownItemProfile}
                    >
                      {profileMenuItem()}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={logOut}
                      style={classes.dropdownItemLogout}
                    >
                      Log out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <>
                <Menu.Item>
                  <SignInButton onClick={goToLogin}>Sign in</SignInButton>
                </Menu.Item>

                <Menu.Item style={classes.menuItemSignup}>
                  <SignUpButton color="orange" onClick={goToSignUp}>
                    Sign up
                  </SignUpButton>
                </Menu.Item>
              </>
            )}
          </Menu>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={2} computer={1} />
      </Grid>
      <Divider style={classes.divider} />
    </>
  );
}

export default ResponsiveBig;
